import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

import { KEYCLOAK_API } from "const";
import {
  authSliceActions,
  getClientId,
  logoutThunk,
  saveTokensInStorageThunk,
} from "store/reducers/auth";
import type { RootState } from "store/store";

import type {
  AuthorizationCode,
  AuthResponse,
  LogoutResponse,
  RefreshToken,
  SubjectToken,
} from "./types";
import { GRAND_TYPE } from "./types";
import { getRedirectUri, keycloakApiUrlBuilder } from "./utils";

export const baseQuery = fetchBaseQuery({
  baseUrl: KEYCLOAK_API,

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = (api.getState() as RootState).auth.updateToken;

    // запрос на получение нового токена

    const refetchRefreshTokenQueryFn = async (
      resultPrev?: QueryReturnValue<
        AuthResponse,
        FetchBaseQueryError,
        FetchBaseQueryMeta
      >,
      count = 2
    ): Promise<
      | QueryReturnValue<AuthResponse, FetchBaseQueryError, FetchBaseQueryMeta>
      | undefined
    > => {
      if (!count) {
        return resultPrev;
      }

      // todo Найти в rtk-query способ более лаконично передавать body, при application/x-www-form-urlencoded
      const urlencoded = new URLSearchParams();
      urlencoded.append("refresh_token", refreshToken ?? "");
      urlencoded.append("client_id", getClientId());
      urlencoded.append("grant_type", GRAND_TYPE.REFRESH_TOKEN);

      const result = (await baseQuery(
        {
          url: keycloakApiUrlBuilder(true).updateTokenUrl,
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          body: urlencoded,
        },
        {
          ...api,
          extra: "isUpdateTokenRequest",
        },
        extraOptions
      )) as QueryReturnValue<
        AuthResponse,
        FetchBaseQueryError,
        FetchBaseQueryMeta
      >;

      if (result.error && result.error.status >= 500) {
        return await refetchRefreshTokenQueryFn(result, count - 1);
      }

      return result;
    };

    const refreshResult = await refetchRefreshTokenQueryFn();

    if (
      refreshResult?.data &&
      refreshResult.data.refresh_token &&
      refreshResult.data.access_token
    ) {
      const { refresh_token, access_token } = refreshResult.data;
      api.dispatch(
        saveTokensInStorageThunk({
          updateToken: refresh_token,
        })
      );
      api.dispatch(
        authSliceActions.updateTokens({
          accessToken: access_token,
          updateToken: refresh_token,
        })
      );
      // повторяем изначальный запрос
      result = await baseQuery(args, api, extraOptions);
    } else {
      await api.dispatch(logoutThunk());
    }
  }

  return result;
};

export const authApi = createApi({
  reducerPath: "authApi",
  keepUnusedDataFor: 30,
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    updateToken: builder.mutation<
      AuthResponse,
      {
        refreshToken: RefreshToken;
        clientId: string;
      }
    >({
      query: ({ refreshToken, clientId }) => {
        // todo Найти в rtk-query способ более лаконично передавать body, при application/x-www-form-urlencoded
        const urlencoded = new URLSearchParams();
        urlencoded.append("refresh_token", refreshToken);
        urlencoded.append("client_id", clientId);
        urlencoded.append("grant_type", GRAND_TYPE.REFRESH_TOKEN);

        return {
          url: keycloakApiUrlBuilder().updateTokenUrl,
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: urlencoded,
        };
      },
    }),

    getAuthTokensByAuthorizationCode: builder.mutation<
      AuthResponse,
      {
        code: AuthorizationCode;
        clientId: string;
      }
    >({
      query: ({ code, clientId }) => {
        // todo Найти в rtk-query способ более лаконично передавать body, при application/x-www-form-urlencoded
        const urlencoded = new URLSearchParams();
        urlencoded.append("code", code);
        urlencoded.append("client_id", clientId);
        urlencoded.append("redirect_uri", getRedirectUri());
        urlencoded.append("grant_type", GRAND_TYPE.AUTHORIZATION_CODE);
        return {
          url: keycloakApiUrlBuilder().getTokensByAuthorizationCodeUrl,
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: urlencoded,
        };
      },
    }),

    logout: builder.mutation<
      LogoutResponse,
      {
        refreshToken: RefreshToken;
        clientId: string;
      }
    >({
      query: ({ refreshToken, clientId }) => {
        // todo Найти в rtk-query способ более лаконично передавать body, при application/x-www-form-urlencoded
        const urlencoded = new URLSearchParams();
        urlencoded.append("client_id", clientId);
        urlencoded.append("refresh_token", refreshToken);

        return {
          url: keycloakApiUrlBuilder().logoutUrl,
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: urlencoded,
        };
      },
    }),

    exchangeTokens: builder.mutation<
      AuthResponse,
      {
        subjectToken: SubjectToken;
        clientId: string;
      }
    >({
      query: ({ subjectToken, clientId }) => {
        // todo Найти в rtk-query способ более лаконично передавать body, при application/x-www-form-urlencoded
        const urlencoded = new URLSearchParams();
        urlencoded.append("subject_token", subjectToken);
        urlencoded.append("client_id", clientId);
        urlencoded.append("audience", "reset_password");
        urlencoded.append("grant_type", GRAND_TYPE.TOKEN_EXCHANGE);
        urlencoded.append(
          "requested_token_type",
          "urn:ietf:params:oauth:token-type:access_token"
        );

        return {
          url: keycloakApiUrlBuilder().exchangeTokensUrl,
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: urlencoded,
        };
      },
    }),
  }),
});
