import type { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { authApi } from "services/auth";
import { footerApi } from "services/footer";
import { helpRequestApi } from "services/help-request";
import { infoAPI } from "services/info";
import { productsApi } from "services/products";
import { stockIndexApi } from "services/stock-index";
import { strapiContentApi } from "services/strapi/service";

import { appReducer } from "./reducers/app/app-reducer";
import { authReducer } from "./reducers/auth";
import { briefcaseSummarySlice } from "./reducers/briefcase-summary/reducer";
import clientReportingReducer from "./reducers/client-reporting/reducer";
import { documentsSlice } from "./reducers/documents/reducer";
import { individualInvestmentSlice } from "./reducers/individual-investment/reducer";
import { mutualFundsSlice } from "./reducers/mutual-funds";
import { promoReducer } from "./reducers/promo";
import { tabsSlice } from "./reducers/tabs";
import { trustManagementSlice } from "./reducers/trust-management/reducer";

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [footerApi.reducerPath]: footerApi.reducer,
  [infoAPI.reducerPath]: infoAPI.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [strapiContentApi.reducerPath]: strapiContentApi.reducer,
  [helpRequestApi.reducerPath]: helpRequestApi.reducer,
  [stockIndexApi.reducerPath]: stockIndexApi.reducer,
  auth: authReducer,
  app: appReducer,
  documents: documentsSlice.reducer,
  briefcaseSummary: briefcaseSummarySlice.reducer,
  trustManagement: trustManagementSlice.reducer,
  mutualFunds: mutualFundsSlice.reducer,
  individualInvestment: individualInvestmentSlice.reducer,
  clientReporting: clientReportingReducer,
  tabs: tabsSlice.reducer,
  promo: promoReducer,
});

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      infoAPI.middleware,
      productsApi.middleware,
      strapiContentApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
