import { compareAsc, min } from "date-fns";
import isEmpty from "lodash/isEmpty";

import { DATE_FORMAT } from "const";
import type { IGetContactsResponse, IProductsAnalytic } from "types/interface";
import { formatDate } from "utils/formatters";
import { replaceMfNameToShort } from "utils/replace-mf-name";

import { ProductsPieValue } from "../const";

import { maxPossibleDate } from "./const";
import type { IBriefcaseStructureAnalytic } from "./types";

/** метод отбирающий только те контракты, что удовлетворяют выбранному
 *  интервалу (dateFrom и dateTo)
 *  и приводящий их к необходимому для компонента Autocomplete */
export const getConvertData = (
  data: IGetContactsResponse[],
  dateFrom: Date,
  dateTo: Date
): {
  value: string;
  label: string;
}[] => {
  dateTo.setHours(0, 0, 0, 0);
  return data
    .slice()
    .sort((prevItem, currItem) =>
      compareAsc(
        new Date(currItem.durationDateTo),
        new Date(prevItem.durationDateTo)
      )
    )
    .map((el) => ({
      value: `${el.contractID}`,
      label: replaceMfNameToShort(el.contractName),
    }));
};

/** метод возвращающий минимально возможную дату исходя из пришедших
 *  контрактов */
export const getMinDate = (data: IGetContactsResponse[]): Date | undefined =>
  !isEmpty(data)
    ? min(data.map((el) => new Date(el.durationDateFrom)))
    : undefined;

/** метод возвращающий максимальную возможную дату исходя из пришедших
 *  контрактов
 *
 * Берется минимальная дата durationDateTo из открытых на данный момент контрактов
 *  */
export const getMaxContractsDate = (
  data: IGetContactsResponse[]
): Date | undefined => {
  const openedContracts = data.filter((contract) => contract.isOpen);

  return !isEmpty(openedContracts)
    ? min(openedContracts.map((el) => new Date(el.durationDateTo)))
    : undefined;
};

/** метод возвращающий максимальную возможную дату исходя из пришедших
 *  контрактов */
export const getMaxDate = (data: IGetContactsResponse[]): Date => {
  const maxDate =
    getMaxContractsDate(data) || new Date(maxPossibleDate.getTime());
  if (
    formatDate(maxDate, DATE_FORMAT.FULL_DATE) ===
    formatDate(new Date(), DATE_FORMAT.FULL_DATE)
  ) {
    maxDate.setDate(maxDate.getDate() - 1);
  }
  return maxDate;
};

/** Построит данные для блока "Структура портфеля" */
export const buildDataForBriefcaseStructure = (
  products: IProductsAnalytic[],
  blockedProductsId: number[]
): IBriefcaseStructureAnalytic => {
  const legend: IBriefcaseStructureAnalytic["legend"] = {
    [ProductsPieValue.MF]: {
      totalPercent: 0,
      title: "Открытые паевые фонды",
      data: [],
    },
    [ProductsPieValue.TM]: {
      totalPercent: 0,
      title: "Стратегии ДУ",
      data: [],
    },
    [ProductsPieValue.IIA]: {
      totalPercent: 0,
      title: "Стратегии ИИС",
      data: [],
    },
  };

  products.forEach((product) => {
    legend[product.contractType] = {
      ...legend[product.contractType],
      data: [
        ...legend[product.contractType].data,
        {
          ...product,
          isBlocked: blockedProductsId.includes(Number(product.name)),
        },
      ],
    };
  });

  // Считаем сумму процентов по группе
  Object.entries(legend).forEach(([key, value]) => {
    legend[key as ProductsPieValue].totalPercent = value.data.reduce(
      (previousValue, currentValue) =>
        // Из расчета исключаем заблокированные фонды
        currentValue.isBlocked
          ? previousValue
          : previousValue + currentValue.percent,
      0
    );
  });

  // Общая стоимость портфеля
  const totalSum = products.reduce(
    (previousValue, currentValue) =>
      // Исключаем заблокированные фонды
      blockedProductsId.includes(Number(currentValue.name))
        ? previousValue
        : previousValue + currentValue.value,
    0
  );

  return {
    totalSum,
    legend,
  };
};
