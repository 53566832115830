export enum TimePeriod {
  Month = "month",
  Year = "year",
  YTD = "sinceBeginningYear",
  AllTime = "all",
  Null = "",
}

export enum Period {
  Month = "Месяц",
  Year = "Год",
  YTD = "С начала года",
  AllTime = "Все время",
  Null = "",
}

export enum ProductsPieValue {
  MF = "MF",
  TM = "TM",
  IIA = "IIA",
}
