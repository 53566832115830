import { format, min } from "date-fns";

import { DATE_FORMAT } from "../../const";
import { getMinDateOnDatePickerOrContracts } from "../../pages/briefcase/utils";
import type { IGetContactsResponse } from "../../types/interface";
import type { IMatchedProductsAndContracts } from "../briefcase/types";
import type { ISummaryApi } from "../summary/types";

import type { IGetMultipleMainDataRequest } from "./types";

/**
 * Возвращает параметры для запроса MultipleMainDate
 * @param products
 * @param contracts
 * @param customDate
 *
 * @deprecated use getParamsForMultipleMainDateQuery2
 */
export const getParamsForMultipleMainDateQuery = ({
  products,
  contracts,
  customDate,
}: {
  products: ISummaryApi[];

  contracts: IGetContactsResponse[];

  customDate: Date;
}): IGetMultipleMainDataRequest[] =>
  products.map(({ Id }) => {
    const currentContract = contracts.find(
      ({ contractID }) => contractID === Id
    );
    return {
      investmentId: Id,
      dateFrom: format(
        new Date(currentContract!.durationDateFrom),
        DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
      ),
      dateTo: format(
        getMinDateOnDatePickerOrContracts(customDate, [currentContract!]),
        DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
      ),
    };
  });

/**
 * Возвращает параметры для запроса MultipleMainDate
 * @param products
 * @param contracts
 * @param customDate
 */
export const getParamsForMultipleMainDateQuery2 = ({
  products,
  customDate,
}: {
  products: IMatchedProductsAndContracts[];

  customDate: Date;
}): IGetMultipleMainDataRequest[] =>
  products
    .filter((el) => el.product.Rate && el.contract)
    .map(({ contract }) => ({
      investmentId: contract!.contractID,
      dateFrom: format(
        new Date(contract!.durationDateFrom),
        DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
      ),
      dateTo: format(
        min([customDate, new Date(contract!.durationDateTo)]),
        DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
      ),
    }));
