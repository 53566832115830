import { strapiContentApi } from "services/strapi";

import type { AppThunk } from "../../store";

import { appActions } from "./app-reducer";
import type { ISysVarsStateModel } from "./types";

export const saveLastPathInStorageThunk =
  (lastPath: string): AppThunk =>
  () => {
    sessionStorage.setItem("last-path", lastPath);
  };

export const getLastPathInStorageThunk = (): AppThunk => (dispatch) => {
  const lastPath = sessionStorage.getItem("last-path");

  if (lastPath) {
    dispatch(appActions.setLastPathname(lastPath));
  }
};

/** thunk для получения системных переменных */
export const getSystemVariablesThunk =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    const response = await dispatch(
      strapiContentApi.endpoints.getSystemVars.initiate({})
    );

    if ("error" in response) {
      return;
    }

    if (response.data && response.data.data && response.data.data.length > 0) {
      const sysVars: ISysVarsStateModel[] = response.data.data.map(
        ({ attributes }) => ({
          techName: attributes.tech_name,
          name: attributes.name,
          value: attributes.value,
        })
      );

      dispatch(appActions.setSystemVariables(sysVars));
    }
  };
