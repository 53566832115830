import { FUNDS_IDS, INGOS_SITE, INGOS_SITE_ROUTES } from "../const";
import type { IProductOnline } from "../services/online-operation/types";
import { Funds } from "../types";

/**
 * Список доступных пифов
 */
const AVAILABLE_MUTUAL_FUNDS = [
  // Казначейский / Ингосстрах денежный рынок
  Funds.MySafe,
  // Ингосстрах – фонд акций /Ингосстрах Индекс МосБиржи
  Funds.MyShares,
  //пенсионный
  Funds.MyPension,
  // ОПИФ Валютные облигации
  Funds.IngosstrakhForeignCurrencyBonds,
];

/**
 * Список заблокированных фондов для отображения баннера либо мест, где не было данных в период блокировки
 *
 * Ингосстрах – валютные облигации одновременно является и доступным и заблокированным (доступны операции, но отображаем его в баннере)
 */
const UNBLOCKED_MUTUAL_FUNDS = [Funds.IngosstrakhForeignCurrencyBonds];

/** Закрытые ПИФ */
const CLOSED_MUTUAL_FUNDS = [
  // Будущее
  Funds.MyFuture,
  //Облигации
  Funds.MyBonds,
];

/** Получить ids фондов по ключам */
const getMutualFundsIdsByKeys = (keys: Funds[]) => {
  const funds: number[] = [];

  for (const fundKey of keys) {
    funds.push(...FUNDS_IDS[fundKey]);
  }

  return funds;
};

/**
 * Проверить идентификатор фонда на вхождение в список id по ключам
 * @param id Идентификатор
 * @param keys Ключи
 */
const checkFundIncludes = (id: number | string, keys: Funds[]) =>
  getMutualFundsIdsByKeys(keys).includes(Number(id));

/** Проверить доступность пифа по ИД */
export const checkAvailableMutualFundById = (id: number | string) =>
  checkFundIncludes(id, AVAILABLE_MUTUAL_FUNDS);

/** Проверить заблокирован ли фонд для баннера */
export const checkUnblockedMutualFundById = (id: number | string) =>
  checkFundIncludes(id, UNBLOCKED_MUTUAL_FUNDS);

/** Проверить закрыт ли фонд для баннера */
export const checkClosedMutualFundById = (id: number | string) =>
  checkFundIncludes(id, CLOSED_MUTUAL_FUNDS);

/** Получить доступный для оплаты ид фонда по существующим */
export const getAvailableMutualFundById = (
  fundId: number | string
): number | null => {
  let availableMFId: number | null = null;

  for (const fundKey of AVAILABLE_MUTUAL_FUNDS) {
    const currentFundIds = FUNDS_IDS[fundKey];

    const isAvailable = currentFundIds.includes(Number(fundId));

    if (isAvailable) {
      availableMFId = currentFundIds.at(-1) ?? null;
      break;
    }
  }

  return availableMFId;
};

/**
 * Поиск ПИФ по id.
 */
export const generateAvailableMFLinkByFundId = (id: number | string) => {
  const fundId = Number(id);

  switch (true) {
    // Пенсионный
    case FUNDS_IDS[Funds.MyPension].includes(fundId):
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif1`;
    // Облигации
    case FUNDS_IDS[Funds.MyBonds].includes(fundId):
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif3`;
    // Казначейский
    case FUNDS_IDS[Funds.MySafe].includes(fundId):
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif5`;
    // Сбалансированный
    case FUNDS_IDS[Funds.MyFuture].includes(fundId):
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif4`;
    // Ингосстрах – фонд акций
    case FUNDS_IDS[Funds.MyShares].includes(fundId):
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif6`;
    default:
      return;
  }
};

/**
 * Ид продуктов из ответа GetProductOnline и соответствующий ему ид ПИФ
 */
export const PRODUCT_ONLINE_IDS: Record<string, number> = {
  // Funds.MyFuture
  4: 17590,
  // Funds.MyBonds
  1: 17586,
  // Funds.MySafe
  11: 639383,
  // Funds.MyShares
  8: 541872,
  // Funds.MyPension
  18: 17587,
  // Funds.IngosstrakhForeignCurrencyBonds
  5: 7596108,
};

/** Получить id ПИФ по ид из ответа GetProductOnline */
export const getMutualFundIdByProductOnlineId = (
  id: IProductOnline["Id"]
): number | undefined => PRODUCT_ONLINE_IDS[String(id)];
