import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";

import type { Nullable } from "entities/common";
import type { AccessToken, UpdateToken } from "services/auth";
import type { EMAIL_VERIFIED, IClientProfile } from "services/clients/types";
import { summaryApi } from "services/summary/summary-service";

import type { AuthState } from "./types";
import { extractFansyIdFromAccessToken } from "./utils";

const initialState: AuthState = {
  accessToken: null,
  clientInfo: null,
  updateToken: null,
  fansyId: null,
  availableProductTypes: null,
};

const updateTokens = (
  state: Draft<AuthState>,
  {
    payload,
  }: PayloadAction<{
    accessToken: AccessToken;
    updateToken: UpdateToken;
  }>
) => {
  const { accessToken, updateToken } = payload;

  const fansyId = extractFansyIdFromAccessToken(accessToken);

  state.accessToken = accessToken;
  state.updateToken = updateToken;
  state.fansyId = fansyId;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<AccessToken>) => {
      state.accessToken = payload;
    },

    setUpdateToken: (state, { payload }: PayloadAction<UpdateToken>) => {
      state.updateToken = payload;
    },

    setClientInfo: (
      state,
      { payload }: PayloadAction<Nullable<IClientProfile>>
    ) => {
      state.clientInfo = payload;
    },

    setClientEmailVerified: (
      state,
      { payload }: PayloadAction<EMAIL_VERIFIED>
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.clientInfo!.emailVerified = payload;
    },
    updateTokens,

    clearStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      summaryApi.endpoints.getSummaryStructure.matchFulfilled,
      (state, { payload }) => {
        state.availableProductTypes = uniq(
          payload.map((product) => product.Type)
        );
      }
    );
  },
});

export const authSliceActions = authSlice.actions;
export const authReducer = authSlice.reducer;
