import { isAfter, isWithinInterval } from "date-fns";

import { DATE_FORMAT } from "../const";

import { formatDate } from "./formatters";

export const today = new Date();
export const todayLocaleRu = formatDate(today, DATE_FORMAT.FULL_DATE_WITH_DASH);
export const DATE_MF_BLOCK = new Date("2022-02-25T00:00:00+03:00");
/** Дата последнего дня, когда пиф был заблокирован (с 17.10.23 уже разблокирован) */
export const DATE_MF_UNBLOCK = new Date("2023-10-16T00:00:00+03:00");

/** После даты блокировки */
export const isAfterBlockDate = (date: Date) => isAfter(date, DATE_MF_BLOCK);

/** Дата между датами блокировки и разблокировки */
export const isBetweenBlockedRange = (date: Date) =>
  isWithinInterval(date, { start: DATE_MF_BLOCK, end: DATE_MF_UNBLOCK });
