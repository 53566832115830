import { useEffect } from "react";

import { useLocation } from "react-router-dom";

/** Скрол наверх при изменении маршрута */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    /** TODO возможно, когда в будущем не будет проблем с пакетами из-за инфраструктуры,
     *  следует использовать react-scroll для анимации */
    scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
