import type { RootState } from "store/store";

/** Выбрать состояние приобретения паев */
const selectPurchase = (state: RootState) => state.mutualFunds.purchase;
/** Выбрать состояние погашения паев */
const selectRepayment = (state: RootState) => state.mutualFunds.repayment;
/** Выбрать состояние обмена паев */
const selectExchange = (state: RootState) => state.mutualFunds.exchange;

/** Cелекторы онлайн операций */
export const mutualFundsSelectors = {
  selectPurchase,
  selectRepayment,
  selectExchange,
};
