/* eslint-disable import/no-duplicates */
import { format, parse } from "date-fns";
import ru from "date-fns/locale/ru";
/* eslint-enable import/no-duplicates */
import type { ColumnType } from "ii-ui-kit";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";

import { Currency, CurrencyCode, DATE_FORMAT } from "const";

import type { AnyRecord } from "../entities/common";

import { expandTemplateString } from "./string";

const getPostfix = (postfix?: string) => {
  if (!postfix) {
    return "";
  }
  return postfix === "%" ? `${postfix}` : `\u00A0${postfix}`;
};

export const getCurrency = (currencyName: string) => {
  switch (currencyName) {
    case CurrencyCode.Ruble: {
      return Currency.Ruble;
    }

    case CurrencyCode.Dollar: {
      return Currency.Dollar;
    }

    case CurrencyCode.Euro: {
      return Currency.Euro;
    }

    default: {
      return currencyName;
    }
  }
};

/**
 * Форматирует число в формат '*** ***,**'
 * @param value => число, которое форматируем
 * @param digits => кол-во чисел после запятой
 * @param postfix => постфикс, который установится после числа
 * @param hasPrefix => признак наличия символа вначале (+ или -)
 */

export const numberFormat = ({
  value,
  digits,
  postfix,
  hasPrefix,
}: {
  value: number | string | null;

  digits?: number;

  postfix?: string;

  hasPrefix?: boolean;
}) => {
  if (value === null) {
    return "";
  }
  const defaultDigits = 2;
  const currentDigits = digits !== undefined ? digits : defaultDigits;
  const stringValue = String(value || 0)
    .trim()
    .replace(",", ".");
  const numberValue = Number(stringValue);
  const numberFormatter = Intl.NumberFormat("ru-RU", {
    maximumFractionDigits: currentDigits,
    minimumFractionDigits: currentDigits,
  });
  const prefix = hasPrefix && numberValue > 0 ? "+" : "";
  return `${prefix}${numberFormatter.format(numberValue)}${getPostfix(
    postfix
  )}`;
};

export const formatDate = (date: Date | string, formatString: string) => {
  const value = typeof date === "string" ? new Date(Date.parse(date)) : date;
  return format(value, formatString, { locale: ru });
};

export const formatDateRu = (date: Date | string, formatString: string) =>
  formatDate(date, formatString);

/**
 * Парсит дата из определенного формата в объект Date
 * @param date
 * @param format
 */
export const parseDate = (date: string, format: DATE_FORMAT | string) =>
  parse(date, format, new Date(), { locale: ru });

export const formatFullDateFullTimeWithSymbolHour = (value: string) => {
  const date = parseDate(value, DATE_FORMAT.FULL_DATE_AND_FULL_TIME);
  return formatDate(date, DATE_FORMAT.FULL_DATE_AND_FULL_TIME);
};

/**
 * Форматирует title колонки и добавляет постфикс через запятую.
 * @param el
 * @param dataKeys
 * @param postfix
 */
export const formatColumnTitleWithPrefix = <T extends AnyRecord>(
  el: ColumnType<T>,
  dataKeys: ColumnType<T>["dataIndex"][],
  postfix: string
) => ({
  ...el,
  ...(dataKeys.includes(el.dataIndex)
    ? { title: `${el.title}, ${postfix}` }
    : {}),
});

/**
 * Форматирует числовое значение приводя его к стандартному сокращению.
 * пример:
 * 1 => 1
 * 1 000 => 1 тыс
 * 1 000 => 1 млн
 * 1 000 => 1 млрд
 * 1 000 => 1 трлн
 * @param value
 */

export const formatterNumberToAbbreviation = (value: number | string) => {
  const number = Number(value);
  const thousand = 1000;
  const million = 1000000;
  const billion = 1000000000;
  const trillion = 1000000000000;

  if (
    (number >= thousand && million > number) ||
    (number <= -thousand && -million < number)
  ) {
    return `${(number / thousand).toFixed()} тыс`;
  }
  if (
    (number >= million && billion > number) ||
    (number <= -million && -billion < number)
  ) {
    return `${(number / million).toFixed()} млн`;
  }
  if (
    (number >= billion && trillion > number) ||
    (number <= -billion && -trillion < number)
  ) {
    return `${(number / billion).toFixed()} млрд`;
  }
  if (number >= trillion || number <= -trillion) {
    return `${(number / trillion).toFixed()} трлн`;
  }
  return number;
};

/**
 * Форматирует строку где каждое новое слово начинается с заглавной буквы,
 * а остальные превращает в нижний регистр.
 */
export const formatStringToStartCase = (str: string) =>
  startCase(str.toLowerCase());

/**
 * Форматирует строку где первое слово начинает с заглавной буквы
 * @param {string} str - Строка котору нужно преобразовать
 * @param {boolean} [condition=true] - Условие
 */
export const formatStringToFirstUpperWithCondition = (
  str: string,
  condition = true
) => (condition ? upperFirst(str) : str);

/** Форматирует номер телефона к единому шаблону: +7 (999) 999-99-99 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const cleanedTrimAndChangeFirstNumber = phoneNumber
    .replace(/\D/g, "")
    .replace(/^\d/, "7");
  return expandTemplateString(
    "+9 (999) 999-99-99",
    cleanedTrimAndChangeFirstNumber
  );
};
