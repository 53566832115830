import type {
  IAssetsForPeriodResponse,
  IBondsTable,
  ICurrencyTable,
  IDepositsAndWithdrawalsResponse,
  IDividendsAndCouponsResponse,
  IFundsTable,
  IGetAnalyticRequest,
  IGetAnalyticResponse,
  IGetAnalyticTableRequest,
  IGetContactsRequest,
  IGetContactsResponse,
  IGetFinancialResultsAssetsRequest,
  IGetFinancialResultsDividendsResponse,
  IGetFreeCashResponse,
  IGetMainDataRequest,
  IGetMainDataResponse,
  IGetMultipleMainDataResponse,
  IGetOperationsHistoryResponse,
  IGetTableFreeCashRequest,
  IGetTableRequest,
  IGetYieldDynamicsRequest,
  IGetYieldDynamicsResponse,
  IIISTable,
  IMFsTable,
  IStockTable,
  ITaxesResponse,
} from "types/interface";

import type { RootState } from "../../store/store";
import { authApi } from "../auth";
import { getUrlFromCR } from "../utils";

import type {
  ICheckPdfRequest,
  ICheckPdfResponse,
  IGeneratePdfRequest,
  IGeneratePdfResponse,
  IGetMultipleMainDataRequest,
} from "./types";

export const clientReportingApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    /** Сервис возвращает сводную информацию о стоимости активов, доходе и доходности */
    getMainData: build.query<IGetMainDataResponse, IGetMainDataRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/MainData`),
        method: "POST",
        body: credentials,
      }),
    }),
    /** Получение сводной информации по массиву ид продуктов (пифов/ду/иис и тд) */
    getMultipleMainData: build.query<
      IGetMultipleMainDataResponse,
      IGetMultipleMainDataRequest[]
    >({
      queryFn: async (contracts, api) => {
        const investorId = (api.getState() as RootState).auth.fansyId!;

        const result: IGetMultipleMainDataResponse = await Promise.all(
          contracts.map(async ({ investmentId, dateTo, dateFrom }) => {
            const result = await api
              .dispatch(
                clientReportingApi.endpoints.getMainData.initiate({
                  dateFrom,
                  dateTo,
                  investorId,
                  investmentId,
                })
              )
              .unwrap();
            return { productId: investmentId, data: result };
          })
        );

        return { data: result };
      },
    }),
    getAnalytic: build.query<IGetAnalyticResponse, IGetAnalyticRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/Analytic`),
        method: "POST",
        body: credentials,
      }),
    }),
    getYieldDynamics: build.query<
      IGetYieldDynamicsResponse,
      IGetYieldDynamicsRequest
    >({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/YieldDynamics`),
        method: "POST",
        body: credentials,
      }),
    }),
    getFinancialResultsDividendsAndCoupons: build.query<
      IGetFinancialResultsDividendsResponse,
      IGetFinancialResultsAssetsRequest
    >({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/FinancialResultsDividendsAndCoupons`),
        method: "POST",
        body: credentials,
      }),
    }),
    /** Метод возвращает список контрактов клиента, активных на дату по (dateTo) */
    getContracts: build.query<IGetContactsResponse[], IGetContactsRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/Contracts`),
        method: "POST",
        body: credentials,
      }),
    }),
    getFreeCash: build.query<IGetFreeCashResponse, IGetTableFreeCashRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/FreeCash`),
        method: "POST",
        body: credentials,
      }),
    }),
    getOperationsHistory: build.query<
      IGetOperationsHistoryResponse,
      IGetTableRequest
    >({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/OperationsHistory`),
        method: "POST",
        body: credentials,
      }),
    }),
    getAssetsForPeriod: build.query<IAssetsForPeriodResponse, IGetTableRequest>(
      {
        query: (credentials) => ({
          url: getUrlFromCR(`Report/AssetsForPeriod`),
          method: "POST",
          body: credentials,
        }),
      }
    ),
    getDividendsAndCoupons: build.query<
      IDividendsAndCouponsResponse,
      IGetTableRequest
    >({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/DividendsAndCouponsDetails`),
        method: "POST",
        body: credentials,
      }),
    }),
    getDepositsAndWithdrawals: build.query<
      IDepositsAndWithdrawalsResponse,
      IGetTableRequest
    >({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/DepositsAndWithdrawals`),
        method: "POST",
        body: credentials,
      }),
    }),
    getTaxes: build.query<ITaxesResponse, IGetTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/Taxes`),
        method: "POST",
        body: credentials,
      }),
    }),
    getCurrencyTable: build.query<ICurrencyTable, IGetAnalyticTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/CurrencyTable`),
        method: "POST",
        body: credentials,
      }),
    }),
    getStockTable: build.query<IStockTable, IGetAnalyticTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/StockTable`),
        method: "POST",
        body: credentials,
      }),
    }),
    getBondsTable: build.query<IBondsTable, IGetAnalyticTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/BondsTable`),
        method: "POST",
        body: credentials,
      }),
    }),
    getFundsTable: build.query<IFundsTable, IGetAnalyticTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/FundsTable`),
        method: "POST",
        body: credentials,
      }),
    }),
    /** Метод возвращает данные для структуры портфеля в разрезе ПИФов */
    getMfTable: build.query<IMFsTable, IGetAnalyticTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/MfTable`),
        method: "POST",
        body: credentials,
      }),
    }),
    getIIATable: build.query<IIISTable, IGetAnalyticTableRequest>({
      query: (credentials) => ({
        url: getUrlFromCR(`Report/IIATable`),
        method: "POST",
        body: credentials,
      }),
    }),

    generatePdf: build.mutation<IGeneratePdfResponse, IGeneratePdfRequest>({
      query: (credentials) => ({
        url: getUrlFromCR("Report/GeneratePDF"),
        method: "POST",
        body: credentials,
      }),
    }),

    checkPdf: build.query<ICheckPdfResponse, ICheckPdfRequest>({
      query: (credentials) => ({
        url: getUrlFromCR("Report/CheckPDF"),
        method: "GET",
        params: credentials,
      }),
    }),
  }),
});
