import type {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  MouseEventHandler,
} from "react";
import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import type { InputProps, MenuItemModel, SingleProps } from "ii-ui-kit";
import { Dropdown, Input, Svg, useExhaustiveEffect } from "ii-ui-kit";
import isFunction from "lodash/isFunction";
import { useInView } from "react-intersection-observer";

import { useAutocomplete } from "hooks/use-autocomplete";

import "./index.scss";

export type AutocompleteProps = Pick<
  SingleProps<string>,
  "value" | "onSelect" | "items" | "isOpen" | "onChangeOpen"
> & {
  id: string;

  label: string;

  inputProps?: Omit<InputProps, "id" | "label" | "placeholder">;

  isClearable?: boolean;

  isDisabled?: boolean;

  className?: string;
};

export const EMPTY_ITEM = { value: "", label: "" };

const itemMatched = (item: MenuItemModel, search: string) =>
  item.label?.toUpperCase().includes(search.toUpperCase());

// TODO удалить этот понос
// добавить в uikit нормальный человеческий серчбокс и дропдаун

export const Autocomplete: FC<AutocompleteProps> = ({
  value,
  id,
  label,
  onSelect,
  items,
  isDisabled,
  inputProps,
  isClearable = true,
  className,
  isOpen,
  onChangeOpen,
}) => {
  const [inputValue, setInputValue] = useState(value.label);
  const [open, setOpen] = useState(false);
  const controlRef = useRef<HTMLDivElement>(null);
  const [itemsAutocompleted, setSearch, showNext, reset] = useAutocomplete({
    items,
    pageSize: 10,
    predicate: itemMatched,
  });
  const { ref, inView } = useInView({
    fallbackInView: true,
    initialInView: false,
    triggerOnce: true,
  });

  const setLastItemRef = () => {
    if (!open || !controlRef.current) {
      return;
    }
    const element = controlRef.current.querySelector(
      ".ingos-dropdown__menu-item:last-of-type"
    );
    ref(element);
  };

  useExhaustiveEffect(() => {
    setLastItemRef();
  }, [itemsAutocompleted]);

  useExhaustiveEffect(() => {
    setLastItemRef();
    if (!open) {
      reset();
    }
  }, [open]);

  useExhaustiveEffect(() => {
    if (inView) {
      showNext();
    }
  }, [inView]);

  useEffect(() => {
    setInputValue(value.label);
  }, [value]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    inputProps?.onChange?.(e);
    setInputValue(e.target.value);
    setSearch(e.target.value);
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (open) {
      return;
    }

    inputProps?.onBlur?.(e);
    const cleared = inputValue.trim().length === 0 && isClearable;
    if (cleared) {
      onSelect(EMPTY_ITEM);
    } else {
      setInputValue(value.label);
      setSearch("");
    }
  };

  const onIconClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isDisabled) {
      e.stopPropagation();
      setOpen((prev) => !prev);
    }
  };

  return (
    <div ref={controlRef}>
      <Dropdown
        isDisable={isDisabled}
        mode="single"
        value={value ?? EMPTY_ITEM}
        onSelect={onSelect}
        items={itemsAutocompleted}
        isOpen={isOpen ?? open}
        onChangeOpen={isFunction(onChangeOpen) ? onChangeOpen : setOpen}
        className={classNames("autocomplete", className)}
        control={
          <div
            className={classNames({
              autocomplete__input: true,
              "autocomplete__input--opened": isOpen ?? open,
            })}
          >
            <Input
              disabled={isDisabled}
              id={id}
              label={label}
              placeholder={label}
              value={inputValue}
              {...inputProps}
              onBlur={onBlur}
              onChange={onChange}
            />
            <div onClick={onIconClick} className="autocomplete__icon">
              <Svg name="chevron-down" />
            </div>
          </div>
        }
      />
    </div>
  );
};

const items = [
  {
    value: "1",
    label: "Bank1",
  },
  {
    value: "2",
    label: "Bank2",
  },
];

export const ACDemo = () => {
  const [value, setValue] = useState<MenuItemModel>({
    value: "2",
    label: "Bank2",
  });
  return (
    <div>
      <Autocomplete
        id="autocomplete2342"
        label="Банк или БИК"
        value={value}
        onSelect={setValue}
        items={items}
      />
      <br />
      current selected value: {value?.label}
    </div>
  );
};
