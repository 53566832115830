import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { todayLocaleRu } from "utils/date";

type TrustManagementState = {
  onDate: string;

  minDate: string;

  maxDate: string;
};

const initialState: TrustManagementState = {
  onDate: todayLocaleRu,
  minDate: todayLocaleRu,
  maxDate: todayLocaleRu,
};

export const trustManagementSlice = createSlice({
  name: "trustManagement",
  initialState,
  reducers: {
    setOnDate: (state, { payload }: PayloadAction<string>) => {
      state.onDate = payload;
    },

    setDates: (state, { payload }: PayloadAction<TrustManagementState>) =>
      payload,

    clearStore: () => initialState,
  },
});

export const trustManagementSliceActions = trustManagementSlice.actions;
