import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import {
  initialExchangeState,
  initialPurchaseState,
  initialRepaymentState,
  initialState,
} from "./initial-states";
import type {
  ExchangeOperationState,
  MutualFundsState,
  PurchaseOperationState,
  RepaymentOperationState,
} from "./types";

export const mutualFundsSlice = createSlice({
  name: "mutualFunds",
  initialState,
  reducers: {
    setOnDate: (state, { payload }: PayloadAction<string>) => {
      state.onDate = payload;
    },

    setDates: (
      state,
      {
        payload,
      }: PayloadAction<Pick<MutualFundsState, "onDate" | "minDate" | "maxDate">>
    ) => {
      state.onDate = payload.onDate;
      state.minDate = payload.minDate;
      state.maxDate = payload.maxDate;
    },

    resetPurchase: (state) => {
      state.purchase = initialPurchaseState;
    },

    setPurchaseValue: (
      state,
      { payload }: PayloadAction<Partial<PurchaseOperationState>>
    ) => {
      state.purchase = Object.assign({}, state.purchase, payload);
    },

    resetRepayment: (state) => {
      state.repayment = initialRepaymentState;
    },

    setRepaymentValue: (
      state,
      { payload }: PayloadAction<Partial<RepaymentOperationState>>
    ) => {
      state.repayment = Object.assign({}, state.repayment, payload);
    },

    resetExchange: (state) => {
      state.exchange = initialExchangeState;
    },

    setExchangeValue: (
      state,
      { payload }: PayloadAction<Partial<ExchangeOperationState>>
    ) => {
      state.exchange = Object.assign({}, state.exchange, payload);
    },

    clearStore: () => initialState,
  },
});

export const mutualFundsSliceActions = mutualFundsSlice.actions;
