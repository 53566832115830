import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

const initialState = {
  isPromotionClosed: true,
};

const promoSlice = createSlice({
  name: "promo",
  initialState,
  reducers: {
    setIsPromotionClosed: (state, { payload }: PayloadAction<boolean>) => {
      state.isPromotionClosed = payload;
    },
  },
});

const selectIsPromotionClosed = (state: RootState) =>
  state.promo.isPromotionClosed;

export const promoSelectors = {
  selectIsPromotionClosed,
};

export const promoActions = promoSlice.actions;
export const promoReducer = promoSlice.reducer;
