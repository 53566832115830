import type { Nullable } from "entities/common";

/** Пол */
export enum GENDER {
  /** Мужской */
  MAN = 1,
  /** Женский */
  WOMAN = 2,
}

/** Признак подтвержденной эл. почты */
export enum EMAIL_VERIFIED {
  /** Подтвержден */
  VERIFIED = 1,
  /** Не подтвержден */
  NOT_VERIFIED = 0,
}

/** Модель адреса */
export interface IAddressModel {
  /** Единая строка адреса */
  addressName: string;
}

/** Модель идентификатора для запроса на информацию о профиле клиента */
export type IClientProfileIdsModel = {
  /** Идентификатор клиента во внешней системе */
  extID: string;
  /** Код внешней системы */
  system: "FANSY-STD" | "CR-CRM";
};

/** Модель запроса на информацию о профиле клиента */
export interface IClientProfileRequest {
  /** Идентификаторы клиента в других системах */
  ids: IClientProfileIdsModel[];
}

/** Запрос на подтверждение электронной почты */
export type IClientsVerifyEmailRequest = IClientProfileRequest;

/** Запрос на подтверждение кода */
export interface IClientsVerifyCodeRequest extends IClientProfileRequest {
  /** Код подтверждения */
  code: string;
}

/** Возможные статусы анкеты */
export enum FORM_STATUS {
  /** Новая */
  NEW = 0,
  /** Проверка сведений */
  CHECKING = 1,
  /** Действует */
  VALID = 2,
  /** Отклонена */
  REJECTED = 4,
  /** Устарела */
  OUTDATED = 8,
}

/** Модель ответа на информацию о профиле клиента */
export interface IClientProfile {
  /** Имя */
  firstName: string;
  /** Фамилия */
  lastName: string;
  /** Отчество */
  middleName: string;
  /** Дата рождения */
  birthDate: Nullable<string>;
  /** Место рождения */
  birthPlace: string;
  /** Страна рождения */
  birthCountry: string;
  /** Гражданство */
  citizenships: string;
  /** ИНН */
  inn: string;
  /** Снилс */
  snils: string;
  /** Пол */
  gender: GENDER;
  /** Код, сгенерированный в CRM */
  crmPublicCode: string;
  /** Дата регистрации */
  regDate: string;
  /** Документ удостоверяющий личность */
  personDocument: {
    /** Код документа */
    code: string;
    /** Серия */
    series: string;
    /** Номер */
    number: string;
    /** Дата выдачи */
    issueDate: Nullable<string>;
    /** Кем выдан */
    issuedBy: string;
    /** Код подразделения */
    issueId: string;
  };
  /** Номер телефона */
  mobile: string;
  /** Электронная почта */
  email: string;
  /** Признак верификации почты */
  emailVerified: EMAIL_VERIFIED;
  /** Адрес проживания */
  liveAddress?: IAddressModel;
  /** Адрес регистрации */
  registerAddress?: IAddressModel;
  /** Почтовый адрес */
  postAddress?: IAddressModel;
  /** Состояние анкеты */
  formStatus?: FORM_STATUS;
}
