import React, { useState } from "react";

import classNames from "classnames";
import { format } from "date-fns";
import { SkeletonBase, Tooltip, useExhaustiveEffect } from "ii-ui-kit";

import { CustomTooltip } from "components/common/custom-tooltip";
import { InfoIcon, InfoIconFill } from "components/common/svg";
import Switcher from "components/switcher";
import { Currency, CurrencyCode, DATE_FORMAT } from "const";
import { useAppSelector } from "hooks/redux";
import { clientReportingApi } from "services/client-reporting/client-reporting-servise";
import { stockIndexApi } from "services/stock-index";
import { authSelectors } from "store/reducers/auth";
import { clientReportingSelectors } from "store/reducers/client-reporting/reducer";
import type { IGetAnalyticResponse } from "types/interface";
import { numberFormat } from "utils/formatters";
import type { RemoteData } from "utils/fp";

import type { IValue } from "../types";

import "./free-cash.scss";

interface IProps {
  isMain?: boolean;

  className?: string;
}

const FreeCash = ({ isMain, className }: IProps) => {
  const { investmentId, dateFrom, dateTo, analyticFilter } = useAppSelector(
    clientReportingSelectors.selectClientReportingState
  );

  const CURRENCIES: IValue[] = Object.values(CurrencyCode).map(
    (currencyCode, i) => ({
      value: Object.values(Currency)[i],
      title: currencyCode,
    })
  );

  const [selectedCurrency, setSelectedCurrency] = useState<IValue>(
    CURRENCIES[0]
  );

  const fancyId = useAppSelector(authSelectors.selectFancyId);

  const analytic: RemoteData<IGetAnalyticResponse> =
    clientReportingApi.useGetAnalyticQuery({
      investmentId,
      investorId: fancyId!,
      dateFrom: format(dateFrom, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
      dateTo: format(dateTo, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
      productsIndex: analyticFilter.activeProducts.map((el) => el.name),
      classesIndex: analyticFilter.activeClasses.map((el) => el.name),
      assetsIndex: analyticFilter.activeAssets.map((el) => el.name),
    });

  const freeCash =
    analytic.data?.assets.data.find(
      (el) => el.displayName === "Денежные средства"
    )?.value || 0;
  const [divisionResult, setDivisionResult] = useState(freeCash);

  const [getCurrency, getCurrencyState] =
    stockIndexApi.useLazyGetCurrencyQuery();

  useExhaustiveEffect(() => {
    if (selectedCurrency.title === CurrencyCode.Ruble) {
      setDivisionResult(freeCash);
    } else {
      void getCurrency(selectedCurrency.title)
        .unwrap()
        .then((data) => {
          setDivisionResult((freeCash || 0) / data.value);
        });
    }
  }, [selectedCurrency, getCurrency, freeCash]);

  return (
    <div
      className={classNames(className, "briefcase-free-cash", {
        "briefcase-free-cash--main": isMain,
      })}
    >
      <div className="briefcase-free-cash__title">
        <div className="briefcase-free-cash__text">
          Свободные денежные средства
        </div>
        <Tooltip
          overlay={
            <CustomTooltip
              title={
                <div>
                  Неинвестированные денежные средства на брокерских и расчетных
                  счетах во всех брокерах и банках.
                </div>
              }
            />
          }
          placement="top"
        >
          {isMain ? (
            <InfoIconFill className="briefcase-free-cash__icon" />
          ) : (
            <InfoIcon className="briefcase-free-cash__text-icon" />
          )}
        </Tooltip>
      </div>
      <div className="briefcase-free-cash__switcher-block">
        <div className="briefcase-free-cash__value">
          {getCurrencyState.isFetching ? (
            <SkeletonBase width="126px" height="36px" animated />
          ) : (
            numberFormat({ value: divisionResult })
          )}
        </div>
        <Switcher
          values={CURRENCIES}
          setSelectedValue={setSelectedCurrency}
          selectedValue={selectedCurrency}
        />
      </div>
    </div>
  );
};

export default FreeCash;
