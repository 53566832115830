import { authApi } from "../auth";
import { getUrlFromPA } from "../utils";

import type { ISummaryApi } from "./types";

export const summaryApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Структура портфеля
     */
    getSummaryStructure: build.query<
      ISummaryApi[],
      {
        onDate: string;
      }
    >({
      query: ({ onDate }) => ({
        url: getUrlFromPA(`PA/Summary/${onDate}`),
        method: "GET",
      }),
    }),
  }),
});
