import React from "react";

import "./index.scss";

interface ICustomTooltipProps {
  icon?: JSX.Element;

  title?: string | JSX.Element;

  subtitle?: string;
}

export const CustomTooltip = ({
  icon,
  title,
  subtitle,
}: ICustomTooltipProps) => (
  <div className="custom-tooltip">
    {Boolean(icon) && <div className="custom-tooltip__title-icon">{icon}</div>}
    <div className="custom-tooltip__title-container">
      {Boolean(title) && <div className="custom-tooltip__title">{title}</div>}
      {Boolean(subtitle) && (
        <div className="custom-tooltip__subtitle">{subtitle}</div>
      )}
    </div>
  </div>
);
