import type {
  IGetAnalyticResponse,
  IGetContactsResponse,
  IGetMainDataResponse,
  IGetMultipleMainDataResponse,
  IMFsTable,
} from "../../types/interface";
import type { ISummaryApi } from "../summary/types";

export enum MatchedProductsAndContractsEnum {
  isWithinInterval = "isWithinInterval",
  isNotHasContract = "isNotHasContract",
  isHasContractAndRateZero = "isHasContractAndRateZero",
  isNotHasContractAndRateZero = "isNotHasContractAndRateZero",
}

export interface IMatchedProductsAndContracts {
  type?: MatchedProductsAndContractsEnum;

  contract: IGetContactsResponse | null;

  product: ISummaryApi;
}

/** Тип для асинхронной санки getCommonBriefcaseDataThunk*/
export interface IGetCommonBriefcaseDataThunk {
  /** Текущая дата */
  onDate: Date;
  /** Минимальная дата по продуктам */
  minDate: Date;
  /** Максимальная дата по продуктам */
  maxDate: Date;
  /** Ответ метода /Report/MainData по переданным продуктам */
  multipleMainData: IGetMultipleMainDataResponse;
  /** Продукты из ответа Summary и Contracts у которых совпали id */
  matchedProductsAndContracts: IMatchedProductsAndContracts[];
  /** Продукты из ответа Summary */
  summaryProductsWithTypes: ISummaryApi[];
}

/** Тип для запроса ИИС, ДУ */
export interface IGetBriefcaseStructureBaseResponse {
  /** Минимальная дата по продуктам */
  minDate: Date;
  /** Максимальная дата по продуктам */
  maxDate: Date;
  /** Ответ метода /Report/MainData по переданным продуктам */
  multipleMainData: IGetMultipleMainDataResponse;
  /** Продукты из ответа Summary и Contracts у которых совпали id */
  matchedProductsAndContracts: IMatchedProductsAndContracts[];
}

/** Тип для запроса ПИФ */
export interface IGetMFBriefcaseStructureResponse {
  /** Минимальная дата по продуктам */
  minDate: Date;
  /** Максимальная дата по продуктам */
  maxDate: Date;
  /** Ответ метода /Report/MainData по переданным продуктам */
  multipleMainData: IGetMultipleMainDataResponse;
  /** Продукты из ответа Summary и Contracts у которых совпали id */
  matchedProductsAndContracts: IMatchedProductsAndContracts[];

  mfTable: IMFsTable;

  analytic: IGetAnalyticResponse;
}

/** Тип для запроса в Общем портфеле */
export interface IGetBriefcaseStructureResponse
  extends IGetMFBriefcaseStructureResponse {
  totalMainData: IGetMainDataResponse;

  summaryProducts: ISummaryApi[];
}

export interface IGetMFBriefcaseStructureRequest {
  onDate: string;
}
