import React from "react";

import { useExhaustiveEffect } from "ii-ui-kit";

import { BUILD_NUMBER, IS_PROD } from "const";
import { useAppDispatch } from "hooks/redux";
import { appActions } from "store/reducers/app/app-reducer";
import {
  getLastPathInStorageThunk,
  getSystemVariablesThunk,
} from "store/reducers/app/thunks";
import { recoveryTokensThunk } from "store/reducers/auth";
import { promoThunks } from "store/reducers/promo";

import { App } from "./app";

declare global {
  // eslint-disable-next-line
  interface Window {
    buildNumber: string | undefined;
  }
}

export const AppContainer = () => {
  const dispatch = useAppDispatch();

  useExhaustiveEffect(() => {
    async function initApp() {
      try {
        dispatch(appActions.setIsLoading(true));
        if (!IS_PROD) {
          window.buildNumber = BUILD_NUMBER;
        }
        dispatch(getLastPathInStorageThunk());
        await dispatch(recoveryTokensThunk());
        await dispatch(getSystemVariablesThunk());
        dispatch(promoThunks.readIsPromotionClosedThunk());
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }

    void initApp();
  }, []);

  return <App />;
};
