import type { DetailedHTMLProps, FC, HTMLAttributes } from "react";

import classNames from "classnames";

import "./index.scss";

type DefaultDivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface IProps extends DefaultDivProps {
  variant?: "primary" | "secondary" | "third" | "fourth" | "fifth" | "sixth";
}

/**
 * Обертка с тенью и предустановленными вариантами "padding"
 * - variant = "primary"; Mobile: padding: 32px 16px; PC: padding: 32px;
 * - variant = "secondary"; padding: 16px;
 * - variant = "third"; padding: initial;
 * - variant = "fourth"; Mobile: padding: 32px 16px; PC: padding: 32px 32px 0;
 * - variant = "fifth"; Mobile: padding: 32px 16px; PC: padding: 48px;
 */
export const Paper: FC<IProps> = ({
  className,
  children,
  variant = "primary",
  ...rest
}) => (
  <div
    className={classNames("paper", `paper--${variant}`, className)}
    {...rest}
  >
    {children}
  </div>
);
