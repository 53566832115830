import { useAppDispatch, useAppSelector } from "hooks/redux";
import { appThunks } from "store/reducers/app/app-reducer";
import { authSelectors, getAuthKeycloakUrl } from "store/reducers/auth";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isUserAuthorized = useAppSelector(authSelectors.selectIsUserAuthorized);
  const dispatch = useAppDispatch();

  if (!isUserAuthorized) {
    dispatch(appThunks.saveLastPathInStorageThunk(location.pathname));
    // Если не авторизованы выполняем переход на форму аутентификации
    location.replace(getAuthKeycloakUrl());

    return null;
  }

  return children;
};
