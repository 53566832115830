import { EMPTY_ITEM } from "components/common/autocomplete";
import { todayLocaleRu } from "utils/date";

import type {
  ExchangeOperationState,
  MutualFundsState,
  PurchaseOperationState,
  RepaymentOperationState,
} from "./types";

export const initialExchangeState: ExchangeOperationState = {
  activeWizard: null,
  selectedFundBefore: { selectItem: EMPTY_ITEM, fund: null },
  selectedFundAfter: { selectItem: EMPTY_ITEM, fund: null },
  selectedBank: { selectItem: EMPTY_ITEM, bank: null },
  isShowSign: false,
  checkingAccount: "",
  quantity: "",
  idsForExchange: [],
};

export const initialPurchaseState: PurchaseOperationState = {
  activeWizard: null,
  selectedFundOrder: { selectItem: EMPTY_ITEM, fund: null },
  selectedBank: { selectItem: EMPTY_ITEM, bank: null },
  isShowSign: false,
  checkingAccount: "",
};

export const initialRepaymentState: RepaymentOperationState = {
  activeWizard: null,
  selectedFund: { selectItem: EMPTY_ITEM, fund: null },
  selectedBank: null,
  selectedFundsByAccount: [],
  fundsBySelected: [],
  isShowSign: false,
  checkingAccount: "",
};

export const initialState: MutualFundsState = {
  onDate: todayLocaleRu,
  minDate: todayLocaleRu,
  maxDate: todayLocaleRu,
  purchase: initialPurchaseState,
  repayment: initialRepaymentState,
  exchange: initialExchangeState,
};
