import { createAsyncThunk } from "@reduxjs/toolkit";

import { FAKE_AUTH, IS_DEV } from "const";
import type { Nullable } from "entities/common";
import type { AuthResponse, Tokens } from "services/auth";
import { authApi } from "services/auth";
import { clientsApi } from "services/clients";
import type { IClientProfile } from "services/clients/types";
import { EMAIL_VERIFIED } from "services/clients/types";

import type { AppThunk, RootState } from "../../store";
import { appActions } from "../app/app-reducer";
import { processInitialBriefcasesDatesThunk } from "../briefcase-common/thunks";
import { briefcaseSummarySliceActions } from "../briefcase-summary/reducer";
import { clientReportingSliceActions } from "../client-reporting/reducer";
import { documentsSliceActions } from "../documents/reducer";
import { individualInvestmentSliceActions } from "../individual-investment/reducer";
import { mutualFundsSliceActions } from "../mutual-funds/";
import { promoThunks } from "../promo";
import { clearTabsStore } from "../tabs/";
import { trustManagementSliceActions } from "../trust-management/reducer";

import { IS_SALE_MANAGER } from "./consts";
import { authSliceActions } from "./reducer";
import { authSelectors } from "./selectors";
import { getClientId } from "./utils";

const FAKE_AUTH_DATA = {
  ACCESS_TOKEN:
    /* cspell:disable-next-line */
    "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2R1JjVFpJVWRYclR0aEhudjgteUpNdWFlbFJSdGt6eVpIajI0UzBiTmhzIn0.eyJleHAiOjE2Njg0MTY4MjEsImlhdCI6MTY2ODQxNTkyMSwiYXV0aF90aW1lIjoxNjY4NDE1ODk4LCJqdGkiOiI2N2Y3ZWExMS1hNWY5LTQzNjEtOGUyZC02MTBmMjZiMGI5OGIiLCJpc3MiOiJodHRwczovL2tleWNsb2FrLWRldi5pbmdvc2ludmVzdC5ydS9yZWFsbXMvY2xpZW50cyIsImF1ZCI6ImluZ29zaW52ZXN0Iiwic3ViIjoiMDgyMjNmYTEtYWUwNi00OWQ3LTlhOTItMGY5MWFmNzRhOWYxIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibW9iaWxlX2NsaWVudCIsInNlc3Npb25fc3RhdGUiOiIzZDY1NTUyMy0zMGUyLTQ2MTItOWY2Ni0zNTUyNzU5MTI1YmQiLCJzY29wZSI6IiIsInNpZCI6IjNkNjU1NTIzLTMwZTItNDYxMi05ZjY2LTM1NTI3NTkxMjViZCIsImlkRmFuc3kiOiI4NDk4MjkxIn0.F8eTvSnTyAKBEzXAX20AlkzwnsrImJVxfvAeeOvWGHunWUlOr6JABvMVYYcU5kQMpzvV-xR4qHebbxH5HxO7zqFGDCbqDofcBIFkAxRX-WtNiLfDcZLR56emeG21Ni2yTykn5tWNKjnNBL1OZvJiqTcSqMmEh3rZmh6JKrMTlwe_ScMzP1VF-5tgrBwdJAzFCow8gpsl6mU43QmoPdIYueoIK5qdaqrARZhWRh3QwgwjgqgqeBvI1TyG8Ibkx56bdB966xG_eQhfqMvDiCKE8K3M2UP11ahaTe9OmhIYhtiF6d77gUF5Pi_OeXfRHY_YSnYSlsFRYrmCYSngcIAOEA",
  UPDATE_TOKEN:
    /* cspell:disable-next-line */
    "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI0MzcwN2QwOS0xYzMyLTQzMzktOTlkOC01MTUxYWU3MjIwZmUifQ.eyJleHAiOjE2NjY2MjI4MjAsImlhdCI6MTY2NjYyMTAyMCwianRpIjoiNmI2MGYzM2EtY2U5YS00ZTFmLWJhYmEtZjJhODQwMWVhZDkxIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgwL3JlYWxtcy90LWNsaWVudHMiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvcmVhbG1zL3QtY2xpZW50cyIsInN1YiI6IjNkNWFjY2UzLWM2M2MtNGQ5MC04NjRhLTZlYTNhNDY2NDc3MiIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJtb2JpbGVfY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6IjYyNDZlYWY0LWMxZWEtNDNmOC04MzE5LTljNDQ0YjM0MjJjMiIsInNjb3BlIjoiIiwic2lkIjoiNjI0NmVhZjQtYzFlYS00M2Y4LTgzMTktOWM0NDRiMzQyMmMyIn0.DlBPZez4rgBTjdykVpkWk5zLtaj1asxzDsQH6r-WLqQ",
};

const getFakeClientProfile = () =>
  ({
    firstName: "Виталий",
    lastName: "Катков",
    middleName: "Александрович",
    birthDate: "22.01.1969",
    birthPlace: "Москва",
    birthCountry: "Россия",
    citizenships: "Российская Федерация",
    inn: "771543288002",
    snils: "",
    gender: 1,
    crmPublicCode: "AAA00004",
    personDocument: {
      code: "21",
      series: "4035",
      number: "430367",
      issueDate: "11.02.2014",
      issuedBy: "ОТДЕЛЕНИЕМ УФМС РОССИИ ПО ГОР. МОСКВЕ ПО РАЙОНУ ВОЙКОВСКИЙ",
      issueId: "772-030",
    },
    mobile: "79031234567",
    email: "admin@ingosinvest.ru",
    regDate: "2022-07-01T00:00:00.000Z",
    emailVerified: EMAIL_VERIFIED.VERIFIED,
    liveAddress: {
      addressName:
        "125167, Россия, г Москва, ул Красноармейская, дом 14, кв. 10",
    },
    registerAddress: {
      addressName: "123123, Москва, ул. Красноармейская, д.14, кв. 10",
    },
    postAddress: {
      addressName: "123123, Москва, ул. Красноармейская, д.14, кв. 10",
    },
    formStatus: 2,
  } as IClientProfile);

/** Восстановление токенов из localStorage */
export const recoveryTokensThunk =
  (): AppThunk<Promise<unknown>> => async (dispatch) => {
    if (IS_DEV && FAKE_AUTH) {
      dispatch(
        authSliceActions.updateTokens({
          accessToken: FAKE_AUTH_DATA.ACCESS_TOKEN,
          updateToken: FAKE_AUTH_DATA.UPDATE_TOKEN,
        })
      );
      dispatch(authSliceActions.setClientInfo(getFakeClientProfile()));
      await dispatch(processInitialBriefcasesDatesThunk());
      return;
    }

    const authData = localStorage.getItem("authData");

    if (!authData) {
      return;
    }

    const parsedTokens = JSON.parse(authData) as Tokens;

    if (!parsedTokens.updateToken) {
      return;
    }

    // устанавливаем updateToken в стор
    dispatch(authSliceActions.setUpdateToken(parsedTokens.updateToken));

    // получаем новые токены
    const res = await dispatch(
      authApi.endpoints.updateToken.initiate({
        refreshToken: parsedTokens.updateToken,
        clientId: getClientId(),
      })
    );

    if ("error" in res) {
      return;
    }

    const tokens = {
      ...parsedTokens,
      accessToken: res.data.access_token!,
      updateToken: res.data.refresh_token!,
    };

    dispatch(authSliceActions.updateTokens(tokens));

    void dispatch(fetchClientInfoThunk());
    await dispatch(processInitialBriefcasesDatesThunk());
  };

/** Получить и сохранить токены(access+update) по коду авторизации */
export const getAndSaveTokensByAuthCodeThunk =
  (code: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const res = await dispatch(
      authApi.endpoints.getAuthTokensByAuthorizationCode.initiate({
        code,
        clientId: getClientId(),
      })
    );

    if ("error" in res) {
      const error = res.error as {
        data: Pick<AuthResponse, "error" | "error_description">;
      };

      throw new Error(error.data.error);
    }

    const tokens = {
      accessToken: res.data.access_token!,
      updateToken: res.data.refresh_token!,
    };

    dispatch(authSliceActions.updateTokens(tokens));

    dispatch(saveTokensInStorageThunk({ updateToken: tokens.updateToken }));

    void dispatch(fetchClientInfoThunk());
    await dispatch(processInitialBriefcasesDatesThunk());
  };

/** Сохранение токенов в Storage */
export const saveTokensInStorageThunk =
  (authData: { updateToken: Nullable<string> }): AppThunk =>
  () => {
    localStorage.setItem("authData", JSON.stringify(authData));
  };

/** Санк получения информации о пользователе */
export const fetchClientInfoThunk = createAsyncThunk(
  "auth/fetchClientInfo",
  async (_, { dispatch, getState }) => {
    const fansyId = authSelectors.selectFancyId(getState() as RootState);

    const clientProfileResponse = await dispatch(
      clientsApi.endpoints.clientProfile.initiate({
        ids: [
          {
            extID: String(fansyId),
            system: "FANSY-STD",
          },
        ],
      })
    );

    if (
      "error" in clientProfileResponse ||
      clientProfileResponse.data.status !== 200
    ) {
      return;
    }

    dispatch(authSliceActions.setClientInfo(clientProfileResponse.data.body));
  }
);

/**
 * Логаут
 * - Вызов логаут метода
 * - Диспатч события выхода
 * - Удаление токенов из Storage
 * - Чистка стора
 */
export const logoutThunk = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, getState }) => {
    const refreshToken = (getState() as RootState).auth.updateToken;

    if (refreshToken) {
      await dispatch(
        authApi.endpoints.logout.initiate({
          refreshToken,
          clientId: getClientId(),
        })
      );
    }

    localStorage.removeItem(IS_SALE_MANAGER);
    localStorage.removeItem("authData");
    dispatch(promoThunks.clearStorageThunk);
    dispatch(authSliceActions.clearStore());
    dispatch(authApi.util.resetApiState());
    dispatch(briefcaseSummarySliceActions.clearStore());
    dispatch(clientReportingSliceActions.clearStore());
    dispatch(individualInvestmentSliceActions.clearStore());
    dispatch(mutualFundsSliceActions.clearStore());
    dispatch(trustManagementSliceActions.clearStore());
    dispatch(appActions.setLastPathname(""));
    dispatch(clearTabsStore());
    dispatch(documentsSliceActions.clearStore());
  }
);
