import React from "react";

import "./container.scss";

const Container: React.FC = ({ children }): JSX.Element => (
  <div className="common-wrapper">
    <div className="common-wrapper__container">{children}</div>
  </div>
);

export default Container;
