import { promoActions } from "store/reducers/promo/promo-reducer";
import type { AppThunk } from "store/store";

import { PROMO_STORAGE_KEY } from "./consts";

const readIsPromotionClosedThunk = (): AppThunk => (dispatch) => {
  const isPromotionClosed = localStorage.getItem(PROMO_STORAGE_KEY);
  dispatch(promoActions.setIsPromotionClosed(Boolean(isPromotionClosed)));
};

const saveIsPromotionClosedThunk = (): AppThunk => (dispatch) => {
  localStorage.setItem(PROMO_STORAGE_KEY, JSON.stringify(true));
  dispatch(promoActions.setIsPromotionClosed(true));
};

const clearStorageThunk = (): AppThunk => (dispatch) => {
  localStorage.removeItem(PROMO_STORAGE_KEY);
  dispatch(promoActions.setIsPromotionClosed(false));
};

export const promoThunks = {
  readIsPromotionClosedThunk,
  saveIsPromotionClosedThunk,
  clearStorageThunk,
};
