export enum MAIN_TABS {
  BRIEFCASE = "briefcase",
  SHOWCASE = "showcase",
  REPORTING_PERIOD = "reporting-period",
  CHAT = "chat",
  OPERATIONS_HISTORY = "operations-history",
  // Не является табом главных табов, но нужен работы страниц /settings, /documents
  SETTINGS = "settings",
  // Не является табом главных табов, но нужен работы страниц /settings, /documents
  DOCUMENTS = "documents",
}

export enum DOCS_TABS {
  MAIN = "",
  SING = "sign",
  READ = "read",
  UPLOAD = "upload",
}

export enum PIF_TABS {
  BRIEFCASE = "briefcase",
  PURCHASE_PIF = "purchase",
  REPAYMENT_PIF = "repayment",
  EXCHANGE_PIF = "exchange",
  // SUMMARY_REPORT = "summary-report",
  // PURCHASE_ORDERS = "purchase-orders",
  // DETAIL_BY_FUND = "detail-by-fund",
}

export enum TM_TABS {
  TRUST_MANAGEMENT = "trust-management",
  CASH_FLOW_INFORMATION = "cash-flow-information",
  ACCRUED_COMMISSIONS_INFORMATION = "accrued-commissions-information",
  TRANSACTIONS_INFORMATION = "transactions-information",
  BRIEFCASE_ANALYSIS = "briefcase-analysis",
  CASH_DEPOSIT = "cash-deposit",
  BRIEFCASE_DYNAMICS = "briefcase-dynamics",
  CALENDAR_PAYMENTS = "calendar-payments",
  REPORT = "report",
}

export enum IIA_TABS {
  IIA_BRIEFCASE = "iia-briefcase",
  CASH_FLOW_INFORMATION = "cash-flow-information",
  ACCRUED_COMMISSIONS_INFORMATION = "accrued-commissions-information",
  TRANSACTIONS_INFORMATION = "transactions-information",
  CASH_DEPOSIT = "cash-deposit",
  BRIEFCASE_DYNAMICS = "briefcase-dynamics",
  CALENDAR_PAYMENTS = "calendar-payments",
  REPORT = "report",
}
