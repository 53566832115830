import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASE_API_URL_HELP_REQUEST } from "const";

import type {
  IComplaintRequest,
  IComplaintResponse,
  IHelpRequest,
  IHelpResponse,
} from "./types";

export const helpRequestApi = createApi({
  reducerPath: "helpRequestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL_HELP_REQUEST}`,
  }),

  endpoints: (build) => ({
    /**
     * Отправка жалобы в Kafka
     */
    sendAppealForm: build.mutation<IComplaintResponse, IComplaintRequest>({
      query: (body) => ({
        url: "api/v1/ru/complaint",
        method: "POST",
        body,
      }),
    }),
    /**
     * Отправка обращения на консультацию в Kafka
     */
    helpRequest: build.mutation<IHelpResponse, IHelpRequest>({
      query: (credentials) => ({
        url: "api/v1/ru/helprequest",
        method: "POST",
        body: {
          email: credentials.email,
          type: "needHelp",
          source: credentials.source,
          surname: credentials.surname,
          firstName: credentials.firstName,
          middleName: credentials.middleName,
          mobilePhone: credentials.mobilePhone,
          utm: credentials.utm,
          subject: "",
          message: "",
          preferredCallTime: credentials.preferredCallTime,
        },
      }),
    }),
  }),
});
