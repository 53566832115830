import type {
  IStrapiCommonRecordFields,
  IStrapiFileField,
} from "./common-types";

/** системные имена переменных */
export enum TECH_NAMES {
  INCREASE_MF_DEPOSIT_DATE = "date_change_min_sum",
  INCREASE_MF_DEPOSIT_DATE_SECOND = "date_change_min_sum_second",
  INCREASE_MF_DEPOSIT_DATE_THIRD = "date_change_min_sum_third",
}

/** Модель документа из коллекции lk-documents */
export interface ILkDocumentModel extends IStrapiCommonRecordFields {
  name: string;

  creationDate: string;

  document: IStrapiFileField;
}

/** Модель системной переменной из коллекции sysvars */
export interface ISysVarsModel extends IStrapiCommonRecordFields {
  /** Техническое имя */
  tech_name: TECH_NAMES;
  /** Человекочитаемое описание */
  name: string;
  /** Значение */
  value: string;
}

/** Тип записи в коллекции статического текста */
export interface IStaticTextModel extends IStrapiCommonRecordFields {
  /** Техническое имя */
  techName: string;
  /** Текст */
  text: string;
}
