import { Currency } from "const";
import { numberFormat } from "utils/formatters";

export const getTotalProfit = (yieldTotal: number, yieldMoney?: number) => {
  const total = numberFormat({
    value: yieldTotal,
    postfix: Currency.Ruble,
    hasPrefix: true,
  });

  const profit =
    yieldMoney !== undefined
      ? numberFormat({
          value: yieldMoney,
          postfix: "%",
        })
      : undefined;

  return {
    total,
    profit,
  };
};
