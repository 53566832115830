/** Уровень риска */
export enum RiskProfile {
  low = "Низкий",
  medium = "Средний",
  high = "Высокий",
}

/** Модель информации об управляющем */
export interface IManagerModel {
  /**Id Управляющего */
  id: string;
  /** ФИО Управляющего */
  name: string;
  /** Должность управляющего */
  position: string;
  /** Регалии управляющего */
  regalia: string;
  /** Фотография управляющего */
  photo: string;
  /** Комментарий управляющего */
  comment: string;
}

/** Модель рынка */
export interface IMarketModel {
  /** Рынок */
  market: string;
}

/** Справочная информация */
export interface IReferenceInfoModel {
  /** Ссылка */
  link: string;
  /** Название */
  name: string;
}

/** Модель тела ответа на запрос информации по продукту */
export interface IProductInfoById {
  /** id продукта в CRM */
  crmId: string;
  /** id продукта в Fansy */
  fansyId: number;
  /** Краткое наименование */
  name: string;
  /** Полное наименование */
  fullNameProduct: string;
  /** Экранное название продукта */
  siteName: string;
  /** id категории  */
  categoryId: string;
  /** Краткое описание продукта */
  description: string;
  /** Статус */
  status: string;
  /** Обмен ОПИФ */
  fundExchange: string;
  /** Дата завершения формирования фонда */
  fundEndingDate: string;
  /** Benchmark */
  benchmark: string;
  /** Валюта */
  currency: string;
  /** Организационная принадлежность  */
  productBelonging: string;
  /** Необходимо инвестиционное профилирование */
  needInvestProfile: boolean;
  /** Необходим статус квалифицированного инвестора */
  needQualified: boolean;
  /** Риск профиль */
  riskProfile: RiskProfile;
  /** Рекомендуемый срок инвестиций */
  investmentPeriod: string;
  /** Минимальная сумма инвестиций */
  minInvestmentAmount: string;
  /** Минимальная сумма пополнений */
  minDepositAmount: string;
  /** Максимальная сумма инвестирования в год */
  maxInvestmentAmount: string;
  /** Срок выплаты денежных средств при погашении паев */
  termPaymentRedemption: string;
  /** Минимальная сумма инвестирования в год */
  minInvestmentAmountPerYear: string;
  /** Тикер */
  ticker: string;
  /** Средневзвешенная продолжительность (лет) */
  weightedAverageDurationYear: string;
  /** Средний кредитный рейтинг */
  averageCreditRating: string;
  /** Средневзвешенная доходность, % годовых */
  weightedAverageYield: string;
  /** Признак ИИС для Брокера */
  iisForBroker: string;
  /** Право использовать ДС
   *
   * Предоставление права использовать денежные средства, учитываемые на Инвестиционном счете
   */
  rightToUseDS: string;
  /** Ожидаемая доходность (для ДУ и ИИС) */
  expectedReturn: string;
  /** Допустимый риск (для ДУ и ИИС) */
  tolerableRisk: string;
  /** Доля облигаций */
  shareOfBonds: string;
  /** Доля акций */
  proportionOfShares: string;
  /** Дата ребалансировки */
  rebalancingDate: string;
  /** Управляющий */
  manager: IManagerModel;
  /** Рынки */
  markets: IMarketModel[];
  /** ИНН */
  inn: string;
  /** КПП */
  kpp: string;
  /** Лого */
  logo: string;
  /** Презентация по продукту */
  presentation: string;
  /** Справочная информация */
  referenceInfo: IReferenceInfoModel[];
}

/** Модель тела ответа на запрос реквизитов по продукту */
export interface IProductRequisiteApi {
  props: {
    /** Получатель платежа */
    payee: string;
    /** ИНН */
    inn: string;
    /** КПП */
    kpp: string;
    /** Наименование банка */
    bankName: string;
    /** БИК банка */
    bic: string;
    /** Корреспондентский счет */
    corrAccount: string;
    /** Расчетный счет */
    paymentAccount: string;
  }[];
}
