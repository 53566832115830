import { createSlice } from "@reduxjs/toolkit";

import { documentsAPI } from "services/documents/service";
import type { IFilesClientDocuments } from "services/documents/types";

import type { RootState } from "../../store";

export interface IClientReportingState {
  totalDocumentActive: number;

  totalPage: number;

  documents: IFilesClientDocuments[];

  isLoading: boolean;
}

const initialState: IClientReportingState = {
  totalDocumentActive: 0,
  totalPage: 1,
  documents: [],
  isLoading: false,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    clearStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      documentsAPI.endpoints.getClientDocuments.matchFulfilled,
      (state, { payload }) => {
        state.documents = payload.body.files;
        state.totalDocumentActive = payload.body.totalDocumentActive;
        state.totalPage = payload.body.totalPage;
      }
    );
  },
});

const selectTotalDocumentActive = (state: RootState) =>
  state.documents.totalDocumentActive;

const selectDocuments = (state: RootState) => state.documents.documents;

const selectTotalPage = (state: RootState) => state.documents.totalPage;

export const documentsSelectors = {
  selectTotalDocumentActive,
  selectDocuments,
  selectTotalPage,
};

export const documentsSliceActions = documentsSlice.actions;
