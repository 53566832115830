import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { differenceInDays } from "date-fns";

import { TimePeriod } from "pages/client-reporting/const";
import {
  maxPossibleDate,
  minPossibleDate,
} from "pages/client-reporting/main-container/const";
import {
  getMaxDate,
  getMinDate,
} from "pages/client-reporting/main-container/utils";
import { clientReportingApi } from "services/client-reporting/client-reporting-servise";
import type {
  IAnalytic,
  IClientReportingState,
  IGetAnalyticResponse,
  IGetContactsResponse,
  IStore,
} from "types/interface";

const dateFrom = new Date();
const dateTo = new Date();
dateTo.setDate(dateTo.getDate() - 1); // дата сегодня - 1 день
dateFrom.setDate(dateFrom.getDate() - 366); // дата сегодня - 1 день - год

const initialState: IClientReportingState = {
  contracts: [],
  investmentId: null,
  minDate: minPossibleDate,
  maxDate: maxPossibleDate,
  dateFrom,
  dateTo,
  period: TimePeriod.Year,
  isOpenPortfolioStructure: false,
  isHiddenPortfolioStructure: false,
  isShortAssets: true,
  isShortClasses: true,
  hasStocks: false,
  hasBonds: false,
  hasFunds: false,
  hasPIFs: false,
  assets: [],
  analyticFilter: {
    activeProducts: [],
    activeClasses: [],
    activeAssets: [],
  },
};

const setContracts = (
  state: Draft<IClientReportingState>,
  { payload }: PayloadAction<IGetContactsResponse[]>
) => {
  const newDateFrom = getMaxDate(payload) || minPossibleDate;
  newDateFrom.setDate(newDateFrom.getDate() - 365);
  if (
    differenceInDays(
      Number(getMaxDate(payload) || maxPossibleDate),
      Number(getMinDate(payload) || minPossibleDate)
    ) < 365
  ) {
    return {
      ...state,
      contracts: payload,
      minDate: getMinDate(payload) || minPossibleDate,
      maxDate: getMaxDate(payload) || maxPossibleDate,
      dateFrom: getMinDate(payload) || minPossibleDate,
      dateTo: getMaxDate(payload) || maxPossibleDate,
      period: TimePeriod.Null,
    };
  }
  return {
    ...state,
    contracts: payload,
    minDate: getMinDate(payload) || minPossibleDate,
    maxDate: getMaxDate(payload) || maxPossibleDate,
    dateFrom: newDateFrom,
    dateTo: getMaxDate(payload) || maxPossibleDate,
  };
};

const setAnalytic = (
  state: Draft<IClientReportingState>,
  { payload }: PayloadAction<IGetAnalyticResponse>
) => ({
  ...state,
  assets: payload.assets.data,
});

const clientReportingSlice = createSlice({
  name: "clientReporting",
  initialState,
  reducers: {
    setAnalyticFilter: (
      state,
      {
        payload,
      }: PayloadAction<{
        activeValue: IAnalytic | null;

        key: string;
      }>
    ) => ({
      ...state,
      analyticFilter: {
        ...state.analyticFilter,
        [payload.key]: payload.activeValue ? [payload.activeValue] : [],
      },
      isOpenPortfolioStructure: Object.values({
        ...state.analyticFilter,
        [payload.key]: payload.activeValue ? [payload.activeValue] : [],
      })
        .map((filter) => filter.length > 0)
        .includes(true),
    }),

    setIsOpenPortfolioStructure: (state) => ({
      ...state,
      isOpenPortfolioStructure: !state.isOpenPortfolioStructure,
    }),

    setShortData: (
      state,
      {
        payload,
      }: PayloadAction<{
        value: boolean;

        key: "isShortAssets" | "isShortClasses";
      }>
    ) => ({
      ...state,
      [payload.key]: payload.value,
    }),

    setIsHiddenPortfolioStructure: (state) => ({
      ...state,
      isHiddenPortfolioStructure: !state.isHiddenPortfolioStructure,
    }),

    setInvestorId: (
      state,
      {
        payload,
      }: PayloadAction<{
        investorId: number;
      }>
    ) => ({
      ...state,
      investorId: payload.investorId,
    }),

    setSettings: (
      state,
      {
        payload,
      }: PayloadAction<{
        dateFrom: Date;

        dateTo: Date;

        investmentId: string;

        period: TimePeriod;
      }>
    ) => ({
      ...state,
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo,
      investmentId: payload.investmentId ? Number(payload.investmentId) : null,
      period: payload.period,
    }),

    setDate: (
      state,
      {
        payload,
      }: PayloadAction<{
        dateFrom: Date;
        dateTo: Date;
      }>
    ) => ({
      ...state,
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo,
    }),

    setInvestmentId: (
      state,
      {
        payload,
      }: PayloadAction<{
        investmentId: string;
      }>
    ) => ({
      ...state,
      investmentId: payload.investmentId ? Number(payload.investmentId) : null,
    }),

    setPeriod: (
      state,
      {
        payload,
      }: PayloadAction<{
        period: TimePeriod;
      }>
    ) => ({
      ...state,
      period: payload.period,
    }),
    setContracts,

    setHasTable: (
      state,
      {
        payload,
      }: PayloadAction<{
        hasStocks: boolean;

        hasBonds: boolean;

        hasFunds: boolean;

        hasPIFs: boolean;
      }>
    ) => ({
      ...state,
      hasStocks: payload.hasStocks,
      hasBonds: payload.hasBonds,
      hasFunds: payload.hasFunds,
      hasPIFs: payload.hasPIFs,
    }),

    clearStore: () => initialState,
  },

  extraReducers: (builder) =>
    builder
      .addMatcher(
        clientReportingApi.endpoints.getContracts.matchFulfilled,
        setContracts
      )
      .addMatcher(
        clientReportingApi.endpoints.getAnalytic.matchFulfilled,
        setAnalytic
      ),
});

export const clientReportingSliceActions = clientReportingSlice.actions;

export default clientReportingSlice.reducer;

const selectClientReportingState = (state: IStore) => state.clientReporting;

export const clientReportingSelectors = {
  selectClientReportingState,
};
