import { format } from "date-fns";
import orderBy from "lodash/orderBy";

import type { ISummaryApi } from "services/summary/types";
import { SummaryTypeEnum } from "services/summary/types";

import type { PieData } from "../components/common/charts/pie/type";
import { DATE_FORMAT, FUNDS_IDS, fundsQrcData } from "../const";
import type { Funds } from "../types";

export const filterDu = (
  summaryProducts: ISummaryApi[] | undefined
): ISummaryApi[] =>
  summaryProducts?.filter((item) => item.Type === SummaryTypeEnum.DU) ?? [];

export const filterPif = (summary: ISummaryApi[] | undefined): ISummaryApi[] =>
  summary?.filter((item) => item.Type === SummaryTypeEnum.PIF) ?? [];

export const getQrcData = (
  type: SummaryTypeEnum,
  productId: number
): {
  merchantId: string;
  account: string;
} | null => {
  switch (type) {
    case SummaryTypeEnum.PIF:
      return getFundQrcData(productId);
    default:
      return null;
  }
};

export const getFundQrcData = (
  fundId: number
): {
  merchantId: string;
  account: string;
} | null => {
  let fundType: Funds | null = null;

  for (const [key, items] of Object.entries(FUNDS_IDS)) {
    if (items.includes(fundId)) {
      fundType = key as Funds;
      break;
    }
  }
  if (fundType) {
    return fundsQrcData[fundType];
  }

  return null;
};

/**
 *
 * @param name наименование инвестиционного фонда
 * @param isAbbreviation тип инструмента сокращенный
 * @returns сокращенное название инвестиционного фонда
 */
export const shrinkFundName = (
  name: string,
  isAbbreviation?: boolean
): string =>
  !isAbbreviation
    ? name.replace("рыночных финансовых инструментов ", "").replaceAll('"', "")
    : name
        .replace("рыночных финансовых инструментов ", "РФИ ")
        .replaceAll('"', "");

/**
 * возвращает разницу между датами в днях
 */
export const getRangeInDays = (dateTo: Date, dateFrom: Date) => {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  return (dateTo.getTime() - dateFrom.getTime()) / millisecondsPerDay;
};

/**
 * формирует дату для отображения с графиках в зависимости от длины
 * выбранного диапазона в днях
 */
export const convertDateToXAxis = (label: string, range: number) => {
  if (range <= 31) {
    return format(new Date(label), DATE_FORMAT.DD_MM);
  }
  if (range > 31 && range <= 365) {
    return format(new Date(label), DATE_FORMAT.MM_YY);
  }
  return format(new Date(label), DATE_FORMAT.YEAR);
};

/**
 * Сортирует данные в PieChart
 */
export const sortPieChartData = (pieData: PieData[]): PieData[] =>
  orderBy(pieData, ["isBlocked", "value", "name"], ["asc", "desc", "asc"]);
