import { INGOS_SITE, INGOS_SITE_ROUTES } from "const";
import { MAIN_TABS } from "store/reducers/tabs";

import type { IPopularFund } from "../types";

import balance from "./fund-logos/balance-fund.webp";
import bond from "./fund-logos/bond-fund.webp";
import pens from "./fund-logos/pens-fund.webp";
import stock from "./fund-logos/stock-fund.webp";
import treasury from "./fund-logos/treasury-fund.webp";
import alfaBankLogo from "./logos/alfa.svg";
import gazpromLogo from "./logos/gazprom.svg";
import gtlkLogo from "./logos/gtlk.svg";
import lukoilLogo from "./logos/lukoil.svg";
import nornikelLogo from "./logos/nornikel.svg";
import rjdLogo from "./logos/rjd.svg";
import rosneftLogo from "./logos/rosneft.svg";
import sberLogo from "./logos/sber.svg";
import yandexLogo from "./logos/YandexclA.svg";

export {
  gazpromLogo,
  lukoilLogo,
  nornikelLogo,
  rjdLogo,
  rosneftLogo,
  sberLogo,
  yandexLogo,
  gtlkLogo,
  alfaBankLogo,
};

export const StockFund: IPopularFund = {
  id: 541872,
  priority: 50,
  fund: {
    title: "Мои акции",
    mainText:
      "Возможность максимизировать доходность с помощью рисковых финансовых инструментов",
    imgSrc: stock,
    altText: "logo",
    profit: "31,5%",
    profitSup: "1",
    riskLevel: "high",
    riskTerm: "Уровень риска",
    detailLink: `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif6`,
    investLink: `/${MAIN_TABS.CHAT}`,
  },
};

export const TreasuryFund: IPopularFund = {
  id: 639383,
  priority: 20,
  fund: {
    title: "Мой сейф",
    mainText:
      "Остается относительно безопасным даже в кризис и может принести доходность при любом сроке инвестирования",
    imgSrc: treasury,
    altText: "logo",
    profit: "45,17%",
    profitSup: "1",
    riskLevel: "low",
    riskTerm: "Уровень риска",
    detailLink: `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif5`,
    investLink: `/${MAIN_TABS.CHAT}`,
  },
};

export const PensFund: IPopularFund = {
  id: 17587,
  priority: 10,
  fund: {
    title: "Моя пенсия",
    mainText: "Перспектива перестать работать раньше, чем вы выйдете на пенсию",
    imgSrc: pens,
    altText: "logo",
    profit: "46,29%",
    profitSup: "1",
    riskLevel: "medium",
    riskTerm: "Уровень риска",
    detailLink: `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif1`,
    investLink: `/${MAIN_TABS.CHAT}`,
  },
};

export const BalanceFund: IPopularFund = {
  id: 17590,
  priority: 40,
  fund: {
    title: "Мое будущее",
    mainText:
      "Путь к обретению финансовой свободы и созданию подушки безопасности",
    imgSrc: balance,
    altText: "logo",
    profit: "19,0%",
    profitSup: "1",
    riskLevel: "medium",
    riskTerm: "Уровень риска",
    detailLink: `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif4`,
    investLink: `/${MAIN_TABS.CHAT}`,
  },
};

export const BondFund: IPopularFund = {
  id: 17586,
  priority: 30,
  fund: {
    title: "Мои облигации",
    mainText:
      "Фонд с низким уровнем риска для инвесторов, которые хотят обгонять ставку по депозитам",
    imgSrc: bond,
    altText: "logo",
    profit: "37,65%",
    profitSup: "1",
    riskLevel: "low",
    riskTerm: "Уровень риска",
    detailLink: `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}/pif3`,
    investLink: `/${MAIN_TABS.CHAT}`,
  },
};

export const funds = [StockFund, TreasuryFund, PensFund, BalanceFund, BondFund];
export const FUNDS_IDS = funds.map((fund) => fund.id);
