import type { ICommonResponse } from "services/common-types";

import { authApi } from "../auth";
import { getUrlFromDocuments } from "../utils";

import type {
  IClientDocumentsResponse,
  IRequestSmsRequest,
  IDocumentFileRequest,
  IUploadFileResponse,
  IDocumentsSignNotificationRequest,
  IDocumentsReadNotificationRequest,
} from "./types";

export const documentsAPI = authApi.injectEndpoints({
  endpoints: (build) => ({
    /** Метод предназначен для получения списка документов клиента */
    getClientDocuments: build.query<
      ICommonResponse<IClientDocumentsResponse>,
      IDocumentFileRequest
    >({
      query: (body) => ({
        url: getUrlFromDocuments(`clients-documents`),
        method: "POST",
        body,
      }),
    }),
    /** Метод предназначен для отправки клиенту кода подтверждения в смс */
    requestSMS: build.mutation<ICommonResponse<void>, IRequestSmsRequest>({
      query: (body) => ({
        url: getUrlFromDocuments(`DocumentsOperations/requestsms`),
        method: "POST",
        body,
      }),
    }),
    /** Метод предназначен для прочтения документа */
    documentsRead: build.mutation<
      ICommonResponse<void>,
      IDocumentsReadNotificationRequest
    >({
      query: (body) => ({
        url: getUrlFromDocuments(
          `DocumentsOperations/DocumentsReadNotification`
        ),
        method: "POST",
        body,
      }),
    }),
    /** Запрос на загрузку файлов в СХД */
    uploadFile: build.mutation<ICommonResponse<IUploadFileResponse>, File[]>({
      query: (files) => {
        const formData = new FormData();

        files.forEach((file, index) => {
          formData.append(`${index + 1}`, file);
        });

        return {
          url: getUrlFromDocuments("clients-documents/UploadFiles"),
          method: "POST",
          body: formData,
        };
      },
    }),
    /** Подписание документов и отправка в crm */
    documentsSign: build.mutation<
      ICommonResponse<void>,
      IDocumentsSignNotificationRequest
    >({
      query: (body) => ({
        url: getUrlFromDocuments(
          "DocumentsOperations/DocumentsSignNotification"
        ),
        method: "POST",
        body,
      }),
    }),
  }),
});
