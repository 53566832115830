import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASE_API_URL_STOCK_INDEX } from "const";

export const stockIndexApi = createApi({
  reducerPath: "stockIndexApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL_STOCK_INDEX}`,
  }),
  endpoints: (build) => ({
    /** Метод возвращает курс валюты */
    getCurrency: build.query<{ value: number }, string>({
      query: (currency) => ({
        url: `api/cbrcurrency?stockTicker=${currency}&useCache=true`,
        method: "GET",
      }),
    }),
  }),
});
