import type { FC } from "react";
import React from "react";

import classNames from "classnames";
import type { PortalProps } from "ii-ui-kit";
import { Informer, Portal } from "ii-ui-kit";

import type { SnackbarPosition, SnackbarState } from "store/reducers/app/types";

import "./snackbar.scss";

interface ISnackBarProps extends SnackbarState {
  position: SnackbarPosition;

  portalContainer: PortalProps["container"];

  onClose: () => void;
}

export const Snackbar: FC<ISnackBarProps> = ({
  position,
  portalContainer,
  title,
  text,
  type,
  isOpen,
  buttonText,
  onButtonClick,
  onClose,
}) =>
  isOpen ? (
    <Portal container={portalContainer}>
      <Informer
        className={classNames("snackbar", `snackbar--${position}`)}
        isOpen
        title={title}
        text={text}
        isDisplayIcon
        isClosable
        type={type}
        onClose={onClose}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
      />
    </Portal>
  ) : null;
