import React from "react";

import { Outlet } from "react-router-dom";

import Container from "components/common/container/container";

import { AuthHeader } from "./logo";

import "./auth-layout.scss";

export const AuthLayout: React.FC = () => (
  <div className="auth-layout">
    <Container>
      <div className="auth-layout__wrapper">
        <AuthHeader />
        <div className="auth-layout__content">
          <Outlet />
        </div>
      </div>
    </Container>
  </div>
);
