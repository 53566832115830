import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

import type {
  IAppState,
  ISysVarsStateModel,
  SnackbarPosition,
  SnackbarState,
} from "./types";

const initialState: IAppState = {
  isLoading: true,
  /**
   * Последний посещенный урл когда происходит принудительный выход
   * из-за истечения токена или его отсутствия
   */
  lastPathname: "",
  snackbar: {
    position: "bottom",
    title: "Что-то пошло не так",
    type: "error",
    text: "Пожалуйста, попробуйте еще раз",
    isOpen: false,
  },
  loader: {
    isLoading: false,
  },
  systemVariables: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setLastPathname: (state, { payload }: PayloadAction<string>) => {
      state.lastPathname = payload;
    },

    setSnackbarAlert: (state, { payload }: PayloadAction<SnackbarState>) => {
      state.snackbar = { ...state.snackbar, ...payload };
    },

    setSnackbarPosition: (
      state,
      { payload }: PayloadAction<SnackbarPosition>
    ) => {
      state.snackbar.position = payload;
    },

    clearSnackbarAlert: (state) => {
      state.snackbar = initialState.snackbar;
    },

    setLoader: (state, { payload }: PayloadAction<IAppState["loader"]>) => {
      state.loader = payload;
    },

    setSystemVariables: (
      state,
      { payload }: PayloadAction<ISysVarsStateModel[]>
    ) => {
      state.systemVariables = payload;
    },
  },
});

const selectIsLoading = (state: RootState) => state.app.isLoading;

const selectLastPathname = (state: RootState) => state.app.lastPathname;

const selectSnackbarState = (state: RootState) => state.app.snackbar;

const selectLoaderState = (state: RootState) => state.app.loader;

const selectSystemVariables = (state: RootState) => state.app.systemVariables;

export const appSelectors = {
  selectIsLoading,
  selectLastPathname,
  selectSnackbarState,
  selectLoaderState,
  selectSystemVariables,
};

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
export * as appThunks from "./thunks";
