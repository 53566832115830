import { createAsyncThunk } from "@reduxjs/toolkit";
import { isValid } from "date-fns";

import { DATE_FORMAT } from "const";
import {
  getMatchedContractBySummaryProductsIds,
  getMatchedContractBySummaryProductsIdsWithoutRateIsZero,
  getMatchedProductsAndContracts,
  getProductsByTypes,
} from "pages/briefcase/utils";
import {
  getMaxContractsDate,
  getMinDate,
} from "pages/client-reporting/main-container/utils";
import type { IGetCommonBriefcaseDataThunk } from "services/briefcase/types";
import { clientReportingApi } from "services/client-reporting/client-reporting-servise";
import { getParamsForMultipleMainDateQuery2 } from "services/client-reporting/utils";
import { summaryApi } from "services/summary/summary-service";
import { SummaryTypeEnum } from "services/summary/types";
import type { AppThunk, RootState } from "store/store";
import { todayLocaleRu } from "utils/date";
import { formatDate, parseDate } from "utils/formatters";

import { authSelectors } from "../auth";
import { briefcaseSummarySliceActions } from "../briefcase-summary/reducer";
import { individualInvestmentSliceActions } from "../individual-investment/reducer";
import { mutualFundsSliceActions } from "../mutual-funds/reducer";
import { trustManagementSliceActions } from "../trust-management/reducer";

const PRODUCTS = [
  {
    value: [SummaryTypeEnum.PIF],
    skipMatching: false,
    // TODO: Remove after fix Summary. Task DGTST-5322
    fixSummaryIIS: true,
    action: mutualFundsSliceActions.setDates,
  },
  {
    value: [
      SummaryTypeEnum.IIS,
      SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION,
      SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED,
    ],
    skipMatching: false,
    // TODO: Remove after fix Summary. Task DGTST-5322
    fixSummaryIIS: true,
    action: individualInvestmentSliceActions.setDates,
  },
  {
    value: [SummaryTypeEnum.DU],
    skipMatching: false,
    action: trustManagementSliceActions.setDates,
  },
  {
    value: [
      SummaryTypeEnum.PIF,
      SummaryTypeEnum.IIS,
      SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION,
      SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED,
      SummaryTypeEnum.DU,
    ],
    skipMatching: true,
    action: briefcaseSummarySliceActions.setDates,
  },
];

/**
 * Инициализация дат(минимальной, максимальной и текущей) для вкладок Общий портфель, ПИФ, ДУ, ИИС
 */
export const processInitialBriefcasesDatesThunk =
  (): AppThunk<Promise<unknown>> => async (dispatch, getState) => {
    const fansyId = authSelectors.selectFancyId(getState())!;

    const summary = await dispatch(
      summaryApi.endpoints.getSummaryStructure.initiate({
        onDate: todayLocaleRu,
      })
    ).unwrap();

    const contracts = await dispatch(
      clientReportingApi.endpoints.getContracts.initiate({
        investorId: fansyId,
      })
    ).unwrap();

    // TODO: Remove "fixSummaryIIS" after fix Summary. Task DGTST-5322
    PRODUCTS.map(({ value, action, skipMatching, fixSummaryIIS }) => {
      let minDate: Date | undefined;
      let maxDate: Date | undefined;

      if (skipMatching) {
        minDate = getMinDate(contracts);
        maxDate = getMaxContractsDate(contracts);
        // TODO: Remove after fix Summary. Task DGTST-5322
      } else if (fixSummaryIIS) {
        const summaryProductsByType = getProductsByTypes(summary, value);

        const matchedContractBySummaryProducts =
          getMatchedContractBySummaryProductsIds(
            contracts,
            summaryProductsByType
          ) ?? [];

        minDate = getMinDate(matchedContractBySummaryProducts);
        maxDate = getMaxContractsDate(matchedContractBySummaryProducts);
        // End remove
      } else {
        const summaryProductsByType = getProductsByTypes(summary, value);

        const matchedContractBySummaryProducts =
          getMatchedContractBySummaryProductsIdsWithoutRateIsZero(
            contracts,
            summaryProductsByType
          ) ?? [];

        minDate = getMinDate(matchedContractBySummaryProducts);
        maxDate = getMaxContractsDate(matchedContractBySummaryProducts);
      }

      return dispatch(
        action({
          minDate:
            minDate && isValid(minDate)
              ? formatDate(minDate, DATE_FORMAT.FULL_DATE_WITH_DASH)
              : todayLocaleRu,
          maxDate:
            maxDate && isValid(maxDate)
              ? formatDate(maxDate, DATE_FORMAT.FULL_DATE_WITH_DASH)
              : todayLocaleRu,
          onDate:
            maxDate && isValid(maxDate)
              ? formatDate(maxDate, DATE_FORMAT.FULL_DATE_WITH_DASH)
              : todayLocaleRu,
        })
      );
    });
  };

/**
 * Возвращает данные на определенную дату и сгруппированные по типу продуктов
 */
export const getCommonBriefcaseDataThunk = createAsyncThunk<
  IGetCommonBriefcaseDataThunk,
  {
    onDate: string;

    minDate: string;

    maxDate: string;

    types: SummaryTypeEnum[];

    selectedContractId: number | null;
  }
>("briefcase/fetchByOnDateAndTypes", async ({ types, ...args }, api) => {
  const fansyId = authSelectors.selectFancyId(api.getState() as RootState)!;

  const { minDate, maxDate, onDate } = {
    onDate: parseDate(args.onDate, DATE_FORMAT.FULL_DATE_WITH_DASH),
    minDate: parseDate(args.minDate, DATE_FORMAT.FULL_DATE_WITH_DASH),
    maxDate: parseDate(args.maxDate, DATE_FORMAT.FULL_DATE_WITH_DASH),
  };

  // Список контрактов инвестора
  const contracts = await api
    .dispatch(
      clientReportingApi.endpoints.getContracts.initiate({
        investorId: fansyId,
      })
    )
    .unwrap();

  // Информация о структуре портфеля
  const summary = await api
    .dispatch(
      summaryApi.endpoints.getSummaryStructure.initiate({
        onDate: args.onDate,
      })
    )
    .unwrap();

  const summaryProducts = getProductsByTypes(summary, types);

  const matchedProductsAndContracts =
    getMatchedProductsAndContracts(
      contracts,
      summaryProducts,
      onDate,
      args.selectedContractId
    ) ?? [];

  // сводная информация о стоимости активов, доходе и доходности для каждого продукта
  const multipleMainData = await api
    .dispatch(
      clientReportingApi.endpoints.getMultipleMainData.initiate(
        getParamsForMultipleMainDateQuery2({
          products: matchedProductsAndContracts,
          customDate: onDate,
        })
      )
    )
    .unwrap();

  return {
    onDate,
    minDate,
    maxDate,
    multipleMainData,
    matchedProductsAndContracts,
    summaryProductsWithTypes: summaryProducts,
  };
});
