import { createSelector } from "@reduxjs/toolkit";

import { DATE_FORMAT } from "const";
import { FORM_STATUS } from "services/clients/types";
import { formatPhoneNumber, parseDate } from "utils/formatters";

import type { RootState } from "../../store";

import type { FormattedFullClientInfo } from "./types";
import { mapDocTypeToDocName } from "./utils";

/** Пользователь авторизован ? */
const selectIsUserAuthorized = (state: RootState) =>
  Boolean(state.auth.accessToken);

/** Fancy ид пользователя */
const selectFancyId = (state: RootState) => state.auth.fansyId;

const selectAvailableProductTypes = (state: RootState) =>
  state.auth.availableProductTypes;

/** Полная информация о пользователе */
export const selectClientInfo = (state: RootState) => state.auth.clientInfo;

/** Полное имя клиента */
const selectFullName = createSelector(selectClientInfo, (clientInfo) => {
  if (!clientInfo) {
    return "";
  }
  return [clientInfo.lastName, clientInfo.firstName, clientInfo.middleName]
    .filter((item) => Boolean(item))
    .join(" ");
});

const selectClientName = createSelector(selectClientInfo, (clientInfo) => ({
  lastName: clientInfo?.lastName,
  firstName: clientInfo?.firstName,
  middleName: clientInfo?.middleName,
}));

const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectPersonalData = createSelector(
  selectClientInfo,
  selectFullName,
  (clientInfo, clientFullName) => {
    if (clientInfo) {
      return {
        name: clientFullName,
        birthDate: clientInfo.birthDate
          ? parseDate(clientInfo.birthDate, DATE_FORMAT.FULL_DATE)
          : null,
        snils: clientInfo.snils,
        email: clientInfo.email,
        phone: formatPhoneNumber(clientInfo.mobile),
        inn: clientInfo.inn,
        factualAddress: clientInfo.liveAddress?.addressName || "",
        postalAddress: clientInfo.postAddress?.addressName || "",
        registrationAddress: clientInfo.registerAddress?.addressName || "",
        document: mapDocTypeToDocName(clientInfo),
        documentNumber: clientInfo.personDocument.number,
        documentSeries: clientInfo.personDocument.series,
        placeOfBirth: clientInfo.birthPlace,
        organization: clientInfo.personDocument.issuedBy,
        dateOfIssue: clientInfo.personDocument.issueDate
          ? parseDate(
              clientInfo.personDocument.issueDate,
              DATE_FORMAT.FULL_DATE
            )
          : null,
      };
    }
    throw new Error(
      "no client info in store. selectPersonalData should be called only in authorized zone"
    );
  }
);

export const selectFormattedFullClientData = createSelector(
  selectClientInfo,
  selectFullName,
  (clientInfo, fullName): FormattedFullClientInfo | null => {
    if (!clientInfo) {
      return null;
    }
    return {
      fullName,
      phone: formatPhoneNumber(clientInfo.mobile),
      email: clientInfo.email,
      docTypeName: mapDocTypeToDocName(clientInfo),
      passport: `${clientInfo.personDocument.series} ${clientInfo.personDocument.number}`,
      docDate: clientInfo.personDocument.issueDate,
      issueId: clientInfo.personDocument.issueId,
      docPlace: clientInfo.personDocument.issuedBy,
      regAddress: clientInfo.registerAddress?.addressName || "",
      liveAddress: clientInfo.liveAddress?.addressName || "",
      postAddress: clientInfo.postAddress?.addressName || "",
      formStatus: clientInfo.formStatus || FORM_STATUS.NEW,
    };
  }
);

/** Получить телефон клиента */
export const selectClientPhone = createSelector(
  selectPersonalData,
  (personalData) => personalData.phone
);

export const authSelectors = {
  selectIsUserAuthorized,
  selectFullName,
  selectClientInfo,
  selectFormattedFullClientData,
  selectFancyId,
  selectClientName,
  selectAccessToken,
  selectAvailableProductTypes,
  selectClientPhone,
};
