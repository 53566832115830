/**
 * Идентификатор типа позиции (-1 - ПИФ, 0 - ДУ, 3- ИИС, 5 - ДУ с целью)
 */
export enum SummaryTypeEnum {
  PIF = -1,
  DU = 0,
  IIS = 3,
  /** Расторгнутый досрочно ИИС с неопределенным типом вычета */
  DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION = -3,
  /** Расторгнутый досрочно ИИС с вычетом в сумме полученных доходов */
  DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED = -2,
  /** Расчеты для общего портеля. Продукта с данным типом не существует. */
  BRIEFCASE = 99,
}

/**
 * Статус продукта
 */
export enum SUMMARY_STATUS {
  TEMPORARY_FROZEN = "Временно заморожен",
  ACTIVE = "Действующий",
  ARCHIVAL = "Архивный",
  DRAFT = "Черновик",
}

/**
 * Обощенная структура оценки позиции портфеля
 */
export interface ISummaryApi {
  /**
   * Идентификатор позиции (int)
   */
  Id: number;
  /**
   * Наименоваие позиции портфеля (Договор ДУ/ИИС, наименование ПИФ)
   */
  Name: string;
  /**
   * Наименование продукта с датой заключения договора
   */
  LongName: string;
  /**
   * Оценка
   */
  Rate: number;
  /**
   * Идентификатор типа позиции (1 - ПИФ, 0 - ДУ, 3 - ИИС, 5 - ЦИ, 99 - Общий портфель)
   */
  Type: SummaryTypeEnum;
  /**
   * Коментарий к оценке
   */
  Comment: string;
  /**
   * Процент занимаемый в портфеле (отображается в пайчарт)
   */
  Percent: number;
  /**
   * Доходность
   */
  Profit: number;
  /**
   * Процент доходности
   */
  Yield: number;
  /**
   * Статус продукта
   */
  Status: SUMMARY_STATUS;
}
