import jwtDecode from "jwt-decode";

import { BASE_API_URL_KEYCLOAK, KEYCLOAK_CONFIG } from "const";
import type { AccessToken } from "services/auth";
import { getRedirectUri } from "services/auth/utils";
import type { IClientProfile } from "services/clients/types";

import { IS_SALE_MANAGER } from "./consts";

export const extractFansyIdFromAccessToken = (
  accessToken: AccessToken
): number =>
  Number(
    jwtDecode<{
      idFansy: string;
    }>(accessToken).idFansy
  );

/** Маппинг кода документа в имя документа */
export const mapDocTypeToDocName = (clientInfo: IClientProfile) => {
  switch (clientInfo.personDocument.code) {
    case "21":
    default: {
      return "Паспорт гражданина РФ";
    }
  }
};

/** Возвращает урл авторизации в кейклоке */
export const getAuthKeycloakUrl = (): string =>
  `${BASE_API_URL_KEYCLOAK}realms/${
    KEYCLOAK_CONFIG.realm
  }/protocol/openid-connect/auth?response_type=code&client_id=${getClientId()}&redirect_uri=${getRedirectUri()}`;

/** Урл для сброса пароля в кейклоке */
export const getResetCredentialsKeycloakUrl = (token: string): string =>
  `${BASE_API_URL_KEYCLOAK}realms/${
    KEYCLOAK_CONFIG.realm
  }/login-actions/reset-credentials?client_id=${getClientId()}&token=${token}`;

/** Возвращает clientId */
export const getClientId = () =>
  localStorage.getItem(IS_SALE_MANAGER)
    ? "sale_client"
    : KEYCLOAK_CONFIG.clientId ?? "";
