import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { appActions, appSelectors } from "./app-reducer";
import type { SnackbarPosition, SnackbarState } from "./types";

export const useSnackbar = (position?: SnackbarPosition) => {
  const snackbarState = useAppSelector(appSelectors.selectSnackbarState);
  const dispatch = useAppDispatch();

  const setSnackbarAlert = useCallback(
    ({
      text,
      type,
      title,
      buttonText,
      onButtonClick,
    }: Omit<SnackbarState, "isOpen">) => {
      dispatch(
        appActions.setSnackbarAlert({
          isOpen: true,
          text,
          type,
          title,
          buttonText,
          onButtonClick,
        })
      );
    },
    [dispatch]
  );

  const clearSnackbarAlert = useCallback(() => {
    dispatch(appActions.clearSnackbarAlert());
  }, [dispatch]);

  const setSnackbarApiError = useCallback(
    (snackbarState?: Partial<Omit<SnackbarState, "isOpen">>) => {
      const defaultState = {
        text: "Попробуйте совершить действие ещё раз или свяжитесь с нами",
        title: "Произошла непредвиденная ошибка",
        buttonText: "Связаться",
        type: "error",
      };

      const state = Object.assign({}, defaultState, snackbarState);

      setSnackbarAlert(state);
    },
    [setSnackbarAlert]
  );

  useEffect(() => {
    if (position) {
      dispatch(appActions.setSnackbarPosition(position));
    }
  }, [dispatch, position]);

  return {
    actions: { setSnackbarAlert, clearSnackbarAlert, setSnackbarApiError },
    snackbarState,
  };
};
