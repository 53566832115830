import type { FC } from "react";

import { Button, Modal } from "ii-ui-kit";

import { checkClosedMutualFundById } from "utils/check-available-mutual-funds";

import "./briefcase-modal.scss";

export interface IBriefcaseModalProps {
  isDisplay: boolean;
  fundId: number;
  onClose: () => void;
}

export const BriefcaseModal: FC<IBriefcaseModalProps> = ({
  isDisplay,
  fundId,
  onClose,
}) => (
  <Modal
    shown={isDisplay}
    closable
    closeOnClickOutside
    swipeable
    onClose={onClose}
  >
    <div className="briefcase-modal">
      <div className="briefcase-modal__title">Уважаемый клиент!</div>
      {checkClosedMutualFundById(fundId) ? (
        <div className="briefcase-modal__description">
          В связи с принятым 22.01.2024 решением управляющей компании о
          прекращении ОПИФ рыночных финансовых инструментов «Мои облигации» и
          ОПИФ рыночных финансовых инструментов «Моё будущее» инвестиционные паи
          указанных фондов управляющей компанией выдаваться больше не будут, а
          денежные средства, перечисленные в оплату инвестиционных паев
          22.01.2024 и позднее, не подлежат включению в состав соответствующего
          фонда и будут возвращены отправителю.
        </div>
      ) : (
        <>
          <div className="briefcase-modal__description">
            <p>
              В связи с изменением типа фонда с открытого на закрытый
              возобновление приема заявок на приобретение, обмен, погашение паев
              не предполагается.
            </p>
            <p>
              В случае поступления в фонд денежных средств от доверительного
              управления, управляющая компания осуществит их выплату пайщикам с
              частичным погашением их инвестиционных паев.
            </p>
          </div>
          <div className="briefcase-modal__reference">
            Также вы можете обратиться в регистратор/депозитарий, на счете в
            котором учитываются ваши инвестиционные паи и узнать условия
            совершения операций по передаче паев в рамках внебиржевых сделок
            иным лицам (не управляющей компании): риск получения убытков от
            таких операций несёт владелец паев.
          </div>
        </>
      )}

      <Button onClick={onClose} className="briefcase-modal__action">
        Понятно
      </Button>
    </div>
  </Modal>
);
