import type { MouseEventHandler } from "react";
import React, { useRef } from "react";

import classNames from "classnames";
import { useClickOutside, useToggle } from "ii-ui-kit";

import type { IValue } from "pages/client-reporting/main-container/main-info-container/main-info/types";

import "./index.scss";

interface IProps {
  values: IValue[];

  selectedValue: IValue;

  setSelectedValue: (value: IValue) => void;
}

const Switcher = ({ values, setSelectedValue, selectedValue }: IProps) => {
  const [isOpenSwitcher, toggleSwitcher] = useToggle(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, toggleSwitcher);

  const handleToggleSwitcher: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    toggleSwitcher();
  };

  return (
    <div className="switcher">
      {isOpenSwitcher && (
        <div className="switcher__menu" ref={wrapperRef}>
          {values
            .sort((a) => (a.value === selectedValue.value ? -1 : 1))
            .map((el) => (
              <div
                className={classNames("switcher__selected", {
                  "switcher__selected--is-active":
                    el.value === selectedValue.value,
                })}
                key={el.value}
                onClick={(e) => {
                  handleToggleSwitcher(e);
                  setSelectedValue(el);
                }}
              >
                <div
                  className={classNames("switcher__selected-value", {
                    "switcher__selected-value--is-active":
                      el.value === selectedValue.value,
                  })}
                >
                  {el.value}
                </div>
              </div>
            ))}
        </div>
      )}
      <div
        className={classNames(
          "switcher__selected",
          "switcher__selected--is-active"
        )}
        onClick={handleToggleSwitcher}
      >
        {selectedValue.value}
      </div>
    </div>
  );
};

export default Switcher;
