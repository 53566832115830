import { Funds } from "types";

/** Базовый url для локального API */
const MOCK_API_URL = "http://localhost:4000/";

/** Урл сайта УК */
export const INGOS_SITE = "https://www.ingosinvest-am.ru";

/** Урл сайта УК без протокола */
export const INGOS_SITE_WITHOUT_PROTOCOL = "www.ingosinvest-am.ru";

/** Урл онбординга ПИФ */
export const ONBOARDING_PIF = process.env.REACT_APP_ONBOARDING_PIF || "";

/** Урл онбординга ДУ */
export const ONBOARDING_DU = process.env.REACT_APP_ONBOARDING_DU || "";

export const BASE_API_URL_PA =
  process.env.REACT_APP_BASE_API_URL_PA ?? MOCK_API_URL;

export const BASE_API_URL_CR =
  process.env.REACT_APP_BASE_API_URL_CR ?? MOCK_API_URL;

export const BASE_API_URL_FOOTER =
  process.env.REACT_APP_BASE_API_URL_FOOTER ?? MOCK_API_URL;

export const BASE_API_URL_STRAPI =
  process.env.REACT_APP_BASE_API_URL_STRAPI ?? MOCK_API_URL;

export const BASE_API_URL_INFO_API =
  process.env.REACT_APP_BASE_API_URL_INFO_API ?? MOCK_API_URL;

export const BASE_API_URL_DOCUMENTS_API =
  process.env.REACT_APP_BASE_API_URL_DOCUMENTS_API ?? MOCK_API_URL;

export const BASE_API_URL_SBP =
  process.env.REACT_APP_BASE_API_URL_SBP ?? MOCK_API_URL;

export const BASE_API_URL_HELP_REQUEST =
  process.env.REACT_APP_BASE_API_URL_HELP_REQUEST ?? MOCK_API_URL;

export const BASE_API_URL_STOCK_INDEX =
  process.env.REACT_APP_BASE_API_URL_STOCK_INDEX ?? MOCK_API_URL;

export const BASE_API_URL_CLIENTS =
  process.env.REACT_APP_BASE_API_URL_CLIENTS ?? MOCK_API_URL;

/** Базовый url до API Keycloak */
export const BASE_API_URL_KEYCLOAK =
  process.env.REACT_APP_BASE_API_URL_KEYCLOAK ?? MOCK_API_URL;

export const BASE_API_URL_PRODUCTS =
  process.env.REACT_APP_BASE_API_URL_PRODUCTS ?? "http://localhost:4000/";

export const KEYCLOAK_API =
  process.env.REACT_APP_BASE_KEYCLOAK_API ?? "http://localhost:4000/";

export const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;

export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_DEV = process.env.NODE_ENV === "development";

export const IS_TESTING_STAGING = process.env.REACT_APP_STAGING === "testing";

export const FAKE_AUTH = process.env.REACT_APP_FAKE_AUTH === "true";

export const REACT_APP_IS_VISIBLE_PROMO_BANNER =
  process.env.REACT_APP_IS_VISIBLE_PROMO_BANNER === "true";

export const KEYCLOAK_CONFIG = {
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
};

export const URLS = {
  MAIN: "/",
  SHOWCASE: {
    MAIN: "/showcase",
    PIF: {
      MAIN: "/showcase/pif",
      BRIEFCASE: "/showcase/pif/briefcase",
      PURCHASE: "/showcase/pif/purchase",
      REPAYMENT: "/showcase/pif/repayment",
      EXCHANGE: "/showcase/pif/exchange",
    },
    TRUST_MANAGEMENT: {
      MAIN: "/showcase/trust-management",
    },
    IIS: {
      MAIN: "/showcase/iia",
      BRIEFCASE: "/showcase/iia/iia-briefcase",
      CASH_FLOW_INFORMATION: "/showcase/iia/cash-flow-information",
      TRANSACTION_INFORMATION: "/showcase/iia/transactions-information",
      CASH_DEPOSIT: "/showcase/iia/cash-deposit",
      REPORT: "/showcase/iia/report",
    },
  },
  DOCUMENTS: {
    MAIN: "/documents",
    SIGN: "/documents/sign",
    READ: "/documents/read",
    UPLOAD: "/documents/upload",
  },
  SETTINGS: "/settings",
  TEST: "/test",
  /** Страница подтверждения входа через Keycloak */
  AUTH_SUCCESS: "/authsuccess",
  /** Страница входа для sales-менеджеров */
  AUTH_SALES: "/auth-sales",
};

export enum DATE_FORMAT {
  YEAR = "yyyy",
  DD_MM = "dd.MM",
  MM_YY = "MM.yy",
  MM_YEAR = "MM.yyyy",
  FULL_DATE_FULL_MONTH = "dd MMMM yyyy",
  FULL_DATE = "dd.MM.yyyy",
  FORMAT_TO_NEW_DATE = "MM-dd-yyyy",
  FULL_DATE_WITH_DASH_REVERSE = "yyyy-MM-dd",
  FULL_DATE_WITH_DASH = "dd-MM-yyyy",
  FULL_DATE_AND_FULL_TIME = "dd.MM.yyyy HH:mm:ss",
  FULL_DATE_AND_TIME = "dd.MM.yyyy HH:mm",
  TIME_AND_FULL_DATE = "HH:mm - dd.MM.yyyy",
}

export enum Currency {
  Ruble = "₽",
  Dollar = "$",
  Euro = "€",
  Yuan = "¥",
}

export enum CurrencyCode {
  Ruble = "RUB",
  Dollar = "USD",
  Euro = "EUR",
  Yuan = "CNY",
}

export enum CurrentPortfolio {
  Stocks = "Акции",
  Bonds = "Облигации",
  Funds = "Биржевые паевые фонды",
  PIFs = "Паевые фонды",
}

export const BG_BACKGROUND_BLACK = "#000";
export const ELEMENT_ACCENT_BLUE = "#004691";
export const ELEMENT_GRAY = "#959BA1";
export const ELEMENT_DISABLE = "#D5DCE0";
export const TEXT_SUCCESS_GREEN = "#24B275";
export const TEXT_ERROR = "#d91212";
export const SECONDARY_SRI_LANKA = "#3BBE97";
export const SECONDARY_KIRIBATI = "#789EFF";
export const ELEMENT_BLUE40 = "#99B5D3";
export const SECONDARY_IRELAND = "#92E27C";

export const CHART_COLORS = [
  "#F473A8",
  "#E1B0FF",
  "#789EFF",
  "#B1DAFF",
  "#66C9D7",
  "#3BBE97",
  "#92E27C",
  "#FFDE2A",
  "#FF9900",
  "#FAB9D3",
  "#F0D8FF",
  "#BCCEFF",
  "#D8ECFF",
  "#B2E4EB",
  "#9DDFCB",
  "#C8F1BD",
  "#FFEE94",
  "#FFCC80",
];

/** Максимальная сумма депозита для ИИС в год */
export const MAX_IIA_DEPOSIT_PER_YEAR = 1_000_000;

/** Ссылки на основном сайте */
export enum INGOS_SITE_ROUTES {
  PIF = "pif",
  TM = "trust-management",
  BPIF = "bpif",
  ONLINE_OPERATIONS = "online-operations",
  CORPORATE_CLIENTS = "corporate-clients",
  INSURANCE_COMPANIES = "insurance-companies",
  PENSION_FUNDS = "pension-funds",
  ENDOWMENT_FUNDS = "endowment-funds",
  SELF_REGULATORY_ORGANIZATIONS = "self-regulatory-organizations",
  ANALYTICS = "analytics",
  PRESS_CENTER = "press-center/news",
  CONTACTS = "contacts",
  STATIC = "static",
  FEEDBACK = "feedback",
  OPEN_INFO = "open-info",
  UPLOAD = "upload",
  BLOG = "blog",
  AGENTS = "agents",
}

export const CURRENT_DOMAIN = window.origin;

/**
 * Ид продуктов ПИФ
 * Последний ID в массиве, всегда доступный для покупки
 */
export const FUNDS_IDS = {
  [Funds.MyFuture]: [17359, 17600, 17590],
  [Funds.MyBonds]: [17357, 17593, 17586],
  [Funds.MySafe]: [615970, 639590, 639383],
  [Funds.MyShares]: [541832, 541873, 541872],
  [Funds.MyPension]: [17358, 17596, 17587],
  [Funds.IngosstrakhInternationalMarkets]: [10277938, 10277947, 10395911],
  [Funds.IngosstrakhAdvancedTechnologies]: [13022748, 13035217, 13035169],
  [Funds.IngosstrakhPreciousMetals]: [12973983, 13035568, 13035253],
  [Funds.IngosstrakhForeignCurrencyBonds]: [7596098, 7596111, 7596108],
};

/**
 * Актуальные merchantId и account для пифов
 */
export const fundsQrcData: {
  [Key in Funds]: { merchantId: string; account: string } | null;
} = {
  [Funds.MyFuture]: {
    merchantId: "MA0000659950",
    account: "40701810000000001302",
  },
  [Funds.MyBonds]: {
    merchantId: "MA0000659958",
    account: "40701810400000001300",
  },
  [Funds.MySafe]: {
    merchantId: "MA0000659945",
    account: "40701810700000001301",
  },
  [Funds.MyShares]: {
    merchantId: "MA0000659962",
    account: "40701810500000001297",
  },
  [Funds.MyPension]: {
    merchantId: "MA0000659956",
    account: "40701810100000001299",
  },
  [Funds.IngosstrakhForeignCurrencyBonds]: {
    merchantId: "MA0000936801",
    account: "40701810600000001294",
  },
  [Funds.IngosstrakhInternationalMarkets]: null,
  [Funds.IngosstrakhAdvancedTechnologies]: null,
  [Funds.IngosstrakhPreciousMetals]: null,
};

/** Ссылки на документы */
export const DOCUMENTS_LINK = {
  // Политика по обработке персональных данных
  /* cspell:disable-next-line */
  HANDLING_PERSONAL_DATA: `${INGOS_SITE}/api/uploads/Politika_obrabotki_P_Dn_ot_15_02_2023_70072661e0.pdf`,
  /** Инструкция по установке PWA */
  PWA_SETUP_INSTRUCTIONS: `${INGOS_SITE}/api/uploads/pwa_setup_instructions_3179ecf9ac.pdf`,
  /** Документ для заключения договоров дарения */
  /* cspell:disable-next-line */
  CONTRACT_DONATION: `${INGOS_SITE}/api/uploads/Priglashenie_napravlyat_oferty_v_OOO_BK_Ingosstrah_Investiczii_red_2_5161c2e2d9.pdf`,
};

/** Направление сортировки */
export enum SORT_DIRECTION {
  ASC = "asc",
  DESC = "desc",
}
