import type { FC } from "react";
import { useEffect } from "react";

import classNames from "classnames";
import { startOfDay } from "date-fns";
import { Button, Svg, Tooltip, useBreakpoint, useToggle } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { CustomTooltip } from "components/common/custom-tooltip";
import { InfoIconFill, LockIcon } from "components/common/svg";
import { Currency } from "const";
import type { IBriefcaseGroupItem } from "entities/briefcase";
import { SummaryTypeEnum } from "services/summary/types";
import { tabsUtils } from "store/reducers/tabs";
import type { InvestMfRoutingState } from "types/routing-state";
import {
  checkAvailableMutualFundById,
  checkUnblockedMutualFundById,
  generateAvailableMFLinkByFundId,
} from "utils/check-available-mutual-funds";
import { isAfterBlockDate, isBetweenBlockedRange } from "utils/date";
import { numberFormat } from "utils/formatters";

import { getTotalProfit } from "../utils";

import "./briefcase-item.scss";

export const BriefcaseItem: FC<IBriefcaseGroupItem> = ({
  id,
  title: name,
  totalCost,
  pif,
  totalYield = 0,
  totalYieldMoney = 0,
  type,
  onOpenModal,
  comment,
  isHold,
}) => {
  const navigate = useNavigate();
  const totalProfit = getTotalProfit(totalYieldMoney, totalYield);

  const isMobile = useBreakpoint((builder) => builder.under("md"));

  const isBlockedInterval =
    pif && pif.date
      ? (isBetweenBlockedRange(startOfDay(pif.date)) &&
          checkUnblockedMutualFundById(id)) ||
        (isAfterBlockDate(startOfDay(pif.date)) && isHold)
      : false;

  const [
    shownMobileActions,
    toggleShownMobileActions,
    toggleShownMobileActionsForce,
  ] = useToggle(false);

  const onCardClick = () => {
    if (isMobile && !isHold) {
      toggleShownMobileActions();
    }
  };

  useEffect(() => {
    toggleShownMobileActionsForce(false);
  }, [isMobile, toggleShownMobileActionsForce]);

  const moreButtonLink = generateAvailableMFLinkByFundId(id);
  const isAvailableFund = checkAvailableMutualFundById(id);

  const handleInvestButtonClick = () => {
    if (isAvailableFund || type !== SummaryTypeEnum.PIF) {
      navigate(tabsUtils.buildURlForInvestButton(type), {
        state: { investProductId: String(id) } as InvestMfRoutingState,
      });
      return;
    }
    onOpenModal?.(id);
  };

  const actionButtons = (
    <>
      {/* FIXME: Как временная мера, скрываем кнопку "Подробнее" для ИИС, ДУ. */}
      <Button
        as="a"
        title="Подробнее"
        size="xs"
        color="secondary"
        className={classNames("briefcase-item__btn", {
          "briefcase-item__btn--hide": !isAvailableFund || !moreButtonLink,
        })}
        href={moreButtonLink}
        target="_blank"
      />
      <Button
        title="Инвестировать"
        size="xs"
        className="briefcase-item__btn"
        onClick={handleInvestButtonClick}
      />
    </>
  );

  return (
    <div
      className={classNames("briefcase-item briefcase-item--product", {
        "briefcase-item--hover": !isHold,
      })}
      onClick={onCardClick}
    >
      <div
        className={classNames("briefcase-item__title", {
          "briefcase-item__title--is-hold": isHold,
        })}
      >
        {name}
      </div>
      {pif ? (
        <div className="briefcase-item__pif-info">
          <div
            className={classNames("pif-info", {
              "pif-info--is-hold": isHold,
            })}
          >
            <div className="pif-info__quantity">
              {numberFormat({
                value: pif.quantity,
                digits: 7,
                postfix: "шт.",
              })}
            </div>
            <div className="pif-info__price-per-unit">
              {isBlockedInterval
                ? "н/д"
                : numberFormat({ value: pif.cost, postfix: Currency.Ruble })}
            </div>
            <div className="pif-info__date">{comment}</div>
          </div>
          {!isHold && (
            <div
              className={classNames("briefcase-item__toggle", {
                "briefcase-item__toggle--rotated": shownMobileActions,
              })}
            >
              <Svg name="chevron-down" />
            </div>
          )}
        </div>
      ) : type === SummaryTypeEnum.DU ? (
        <div className="briefcase-item__tm-description">
          <Tooltip
            overlay={
              <CustomTooltip title="В расчете показателя учитываются удержанные налоги, вознаграждение доверительного управляющего, иные расходы, детальную информацию о которых можно посмотреть в истории операций." />
            }
            placement="topRight"
          >
            <InfoIconFill className="briefcase-item__tm-description-icon" />
          </Tooltip>
          <div className="briefcase-item__comment">{comment}</div>
        </div>
      ) : (
        <div className="briefcase-item__comment">{comment}</div>
      )}
      <div className="briefcase-item__total">
        <div className="cost-absolute">
          {isHold ? (
            <Tooltip
              overlay={
                <CustomTooltip title="В связи с изменением типа фонда с открытого на закрытый возобновление приема заявок на приобретение, обмен, погашение паев не предполагается" />
              }
            >
              <LockIcon className="briefcase-item__pif-info-icon-lock" />
            </Tooltip>
          ) : (
            numberFormat({ value: totalCost, postfix: Currency.Ruble })
          )}
        </div>
        {!isHold && (
          <div className="briefcase-item__total-fin">
            <span
              className={classNames("cost-relative", {
                green: totalYieldMoney > 0,
                red: totalYieldMoney < 0,
              })}
            >
              {totalProfit.total}
            </span>

            {totalYield !== undefined && (
              <span
                className={classNames("cost-relative", {
                  green: totalYield > 0,
                  red: totalYield < 0,
                })}
              >
                &nbsp;({totalProfit.profit})
              </span>
            )}
          </div>
        )}
      </div>
      {!pif && (
        <div
          className={classNames("briefcase-item__toggle", {
            "briefcase-item__toggle--rotated": shownMobileActions,
          })}
        >
          <Svg name="chevron-down" />
        </div>
      )}
      <div className="briefcase-item__buttons">{!isHold && actionButtons}</div>
      <div className="briefcase-item__actions">
        {shownMobileActions && (
          <div
            className={classNames("briefcase-item__actions-buttons", {
              "briefcase-item__actions-button--single": !isAvailableFund,
            })}
          >
            {!isHold && actionButtons}
          </div>
        )}
      </div>
    </div>
  );
};
