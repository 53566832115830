import type { FC } from "react";
import React, { useState } from "react";

import classNames from "classnames";
import type { ButtonProps } from "ii-ui-kit";
import { Button } from "ii-ui-kit";
import { Link, useNavigate } from "react-router-dom";

import { ChevronUpIcon } from "components/common/svg";
import { ONBOARDING_DU, URLS } from "const";
import type { IBriefcaseGroup } from "entities/briefcase";
import { emptyLinkProduct } from "pages/briefcase/utils";
import { SummaryTypeEnum } from "services/summary/types";

import { BriefcaseItem } from "../briefcase-item/briefcase-item";

import "./briefcase-container.scss";

export const BriefcaseContainer: FC<IBriefcaseGroup> = ({
  type,
  title,
  slogan,
  link,
  items,
  onOpenModal,
}) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapse] = useState(false);

  const isEmptyProduct = !items || items.length === 0;

  const handleClick = () => {
    if (!isEmptyProduct) {
      return;
    }
    setIsCollapse((prev) => !prev);
  };

  const generateLinkOrHandler = (): ButtonProps<"a"> => {
    switch (type) {
      case SummaryTypeEnum.PIF: {
        return {
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            navigate(URLS.SHOWCASE.PIF.PURCHASE);
          },
        };
      }
      case SummaryTypeEnum.DU: {
        return {
          href: ONBOARDING_DU,
          target: "_blank",
        };
      }
      default: {
        return {};
      }
    }
  };

  return isEmptyProduct && type === SummaryTypeEnum.IIS ? (
    // позже сюда будет добавлен виджет специальный для ЦИ
    // задача DGTST-3847
    <></>
  ) : (
    <div className="briefcase-collapsed-wrapper">
      <div
        className={classNames("briefcase-box", {
          "empty-product": isEmptyProduct,
        })}
        onClick={handleClick}
      >
        <div className="briefcase-box__title">
          {isEmptyProduct ? (
            <span className="briefcase-box__title-text"> {title}</span>
          ) : (
            <Link className="briefcase-box__title-text" to={link}>
              {title}
            </Link>
          )}
        </div>
        <div
          className={classNames("briefcase-box__subtitle", {
            "chevron-up": !isCollapsed,
            "chevron-down": isCollapsed,
          })}
        >
          {isEmptyProduct ? <ChevronUpIcon /> : <span>за всё время</span>}
        </div>
      </div>
      {!isCollapsed && (
        <div className="briefcase-box__collapsed-items">
          {!isEmptyProduct ? (
            <div className="briefcase-items">
              {items.map((item) => (
                <BriefcaseItem
                  key={`${item.id} ${item.title}`}
                  {...item}
                  onOpenModal={onOpenModal}
                />
              ))}
            </div>
          ) : (
            <>
              <div className="briefcase-slogan">{slogan}</div>
              <div className="briefcase__actions">
                <Button
                  as="a"
                  title="Подробнее"
                  size="xs"
                  color="secondary"
                  href={emptyLinkProduct(type)}
                />
                <Button
                  as="a"
                  title="Инвестировать"
                  size="xs"
                  {...generateLinkOrHandler()}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
