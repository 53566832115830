import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASE_API_URL_PRODUCTS } from "const";
import type { ICommonResponse } from "services/common-types";

import type { IProductInfoById, IProductRequisiteApi } from "./types";

/**
 * Апи к сервису "Продуктовый каталог"
 */
export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL_PRODUCTS }),

  endpoints: (build) => ({
    /**
     * Получить информацию по продукту по fancyId
     */
    getProductInfoById: build.query<
      ICommonResponse<IProductInfoById>,
      {
        fancyId: number;
      }
    >({
      query: (request) => ({
        method: "GET",
        url: `/api/Products/${request.fancyId}`,
      }),
    }),

    /**
     * Получить информацию о нескольких продуктах по списку fancyId
     */
    multipleProductsInfo: build.query<
      ICommonResponse<IProductInfoById>[],
      {
        fancyIds: number[];
      }
    >({
      queryFn: async ({ fancyIds }, api) => {
        const result: ICommonResponse<IProductInfoById>[] = await Promise.all(
          fancyIds.map((id) =>
            api
              .dispatch(
                productsApi.endpoints.getProductInfoById.initiate({
                  fancyId: id,
                })
              )
              .unwrap()
          )
        );

        return { data: result };
      },
    }),

    /** Получение реквизитов для ПИФов */
    getProductRequisitesById: build.query<
      ICommonResponse<IProductRequisiteApi>,
      {
        fancyId: number | string;
      }
    >({
      query: (request) => ({
        method: "GET",
        url: `/api/Products/${request.fancyId}/fundProps`,
      }),
    }),
  }),
});
