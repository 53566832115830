import React from "react";

import "ii-ui-kit/dist/styles.css";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { AppContainer } from "./components/app/app.container";
import * as serviceWorkerRegistration from "./service-worker-registration";
import { store } from "./store/store";
import "./styles/styles.scss";

render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
