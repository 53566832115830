import {
  BASE_API_URL_PA,
  BASE_API_URL_CR,
  BASE_API_URL_SBP,
  BASE_API_URL_CLIENTS,
  BASE_API_URL_DOCUMENTS_API,
} from "../const";

export const getUrlFromCR = (path: string) => `${BASE_API_URL_CR}${path}`;

export const getUrlFromDocuments = (path: string) =>
  `${BASE_API_URL_DOCUMENTS_API}${path}`;

export const getUrlFromPA = (path: string) => `${BASE_API_URL_PA}${path}`;

export const getUrlFromSbp = (path: string) => `${BASE_API_URL_SBP}${path}`;

export const getUrlFromClients = (path: string) =>
  `${BASE_API_URL_CLIENTS}${path}`;
