import { useLayoutEffect, useState } from "react";

import { Button, Modal, useBreakpoint } from "ii-ui-kit";

import { register } from "service-worker-registration";

import "./update-pwa.scss";

export const UpdatePwa = () => {
  const isMobile = useBreakpoint((builder) => builder.under("md"));
  const [showUpdate, setShowUpdate] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [serviceWorker, setServiceWorker] = useState<ServiceWorker | null>(
    null
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setServiceWorker(registration.waiting);
    setShowUpdate(true);
  };

  const onSWSuccess = () => {
    // eslint-disable-next-line no-console
    console.log("App installed as a PWA.");
  };

  const updateSW = () => {
    if (!serviceWorker) {
      setShowUpdate(false);
      return;
    }
    setShowLoading(true);
    serviceWorker.onstatechange = () => {
      if (
        serviceWorker?.state === "activated" &&
        navigator.serviceWorker.controller
      ) {
        serviceWorker.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      }
    };
    setShowUpdate(false);
  };

  const updateSWLater = () => {
    setShowUpdate(false);
  };

  useLayoutEffect(() => {
    register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
  }, []);
  return (
    <Modal shown={showUpdate} onClose={updateSWLater}>
      <div className="update-pwa">
        <h5 className="update-pwa__title">Доступно обновление</h5>
        <Button
          className="update-pwa__action"
          onClick={updateSW}
          isLoading={showLoading}
          size={isMobile ? "m" : "l"}
        >
          Обновить
        </Button>
      </div>
    </Modal>
  );
};
