import type { FC } from "react";
import React from "react";

import { SkeletonBase } from "ii-ui-kit";

import { arrayStub } from "utils/array";

import "./index.scss";

const Item: FC = () => (
  <div className="briefcase-skeleton__block-item">
    <div className="briefcase-skeleton__block-item-row">
      <SkeletonBase width="50%" height="20px" color="dark" animated />
      <SkeletonBase width="30%" height="20px" color="dark" animated />
    </div>
    <div className="briefcase-skeleton__block-item-row">
      <SkeletonBase width="55%" height="20px" color="dark" animated />
      <SkeletonBase width="35%" height="20px" color="dark" animated />
    </div>
  </div>
);

const Info: FC = () => (
  <div className="briefcase-skeleton__info">
    <div className="briefcase-skeleton__info-row">
      <SkeletonBase width="50px" height="16px" animated />
      <SkeletonBase width="50px" height="16px" animated />
    </div>
    <div className="briefcase-skeleton__info-row">
      <SkeletonBase width="40%" height="32px" animated />
      <SkeletonBase width="30%" height="32px" animated />
    </div>
  </div>
);

const Block: FC = () => (
  <SkeletonBase
    width="100%"
    radius="16px"
    className="briefcase-skeleton__block"
    color="gray"
  >
    <SkeletonBase
      width="100px"
      height="20px"
      className="briefcase-skeleton__block-title"
      color="dark"
      animated
    />
    <Item />
    <Item />
  </SkeletonBase>
);

export const BriefcaseSkeleton: FC<{
  blocksCount: number;
}> = ({ blocksCount }) => (
  <SkeletonBase
    width="100%"
    radius="16px"
    color="light"
    className="briefcase-skeleton"
  >
    <Info />
    {arrayStub(blocksCount, (index) => (
      <Block key={index} />
    ))}
  </SkeletonBase>
);
