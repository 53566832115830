import { useCallback, useEffect, useState } from "react";

type AutocompleteState<T> = {
  search: string;
  pageIndex: number;
  hasNextPage: boolean;
  items: T[];
  autocompletedItems: T[];
};

type AutocompleteOptions<T> = {
  items: T[];
  pageSize: number;
  predicate: (item: T, search: string) => boolean;
};

type AutocompleteResult<T> = [
  T[],
  (search: string) => void,
  () => void,
  () => void
];

// TODO удалить это когда в автокомплит и дропдаун завезут виртуализацию
export const useAutocomplete = <T>(
  options: AutocompleteOptions<T>
): AutocompleteResult<T> => {
  const [state, setState] = useState<AutocompleteState<T>>({
    search: "",
    pageIndex: 0,
    hasNextPage:
      options.items.slice(0, options.pageSize).length < options.items.length,
    items: options.items,
    autocompletedItems: options.items.slice(0, options.pageSize),
  });

  const setSearch = useCallback((search: string) => {
    // for updating autocompletedItems even if there was update from '' to ''
    setState((prev) => ({ ...prev, items: [...prev.items], search }));
  }, []);

  const getMatchedItems = useCallback(
    (
      search: string,
      items: T[],
      predicate: (item: T, searchString: string) => boolean
    ) => {
      const noSearch = search.trim().length === 0;
      return noSearch ? items : items.filter((item) => predicate(item, search));
    },
    []
  );

  useEffect(
    () =>
      setState((prev) => ({
        ...prev,
        items: options.items,
      })),
    [options.items]
  );

  useEffect(
    () =>
      setState((prev) => {
        const matchedItems = getMatchedItems(
          state.search,
          state.items,
          options.predicate
        );
        const autocompletedItems = matchedItems.slice(0, options.pageSize);
        const nextItems = matchedItems.slice(
          options.pageSize,
          options.pageSize * 2
        );
        const hasNextPage = nextItems.length > 0;
        return {
          ...prev,
          pageIndex: 0,
          hasNextPage,
          autocompletedItems,
        };
      }),
    [
      getMatchedItems,
      state.items,
      options.pageSize,
      options.predicate,
      state.search,
    ]
  );

  const showNext = useCallback(() => {
    setState((prev) => {
      if (prev.hasNextPage) {
        const pageIndex = prev.pageIndex + 1;
        const [offsetStart, offsetEnd, nextOffsetEnd] = [
          pageIndex * options.pageSize,
          pageIndex * options.pageSize + options.pageSize,
          pageIndex * options.pageSize + options.pageSize * 2,
        ];
        const matchedItems = getMatchedItems(
          prev.search,
          state.items,
          options.predicate
        );
        const autocompletedItems = [
          ...prev.autocompletedItems,
          ...matchedItems.slice(offsetStart, offsetEnd),
        ];
        const nextItems = matchedItems.slice(offsetEnd, nextOffsetEnd);
        const hasNextPage = nextItems.length > 0;
        return {
          ...prev,
          pageIndex,
          autocompletedItems,
          hasNextPage,
        };
      }

      return prev;
    });
  }, [getMatchedItems, state.items, options.pageSize, options.predicate]);

  const reset = useCallback(() => {
    setState({
      search: "",
      pageIndex: 0,
      hasNextPage:
        options.items.slice(0, options.pageSize).length < options.items.length,
      items: options.items,
      autocompletedItems: options.items.slice(0, options.pageSize),
    });
  }, [options.items, options.pageSize]);

  return [state.autocompletedItems, setSearch, showNext, reset];
};
