export type AccessToken = string;
export type UpdateToken = string;
export type RefreshToken = string;
export type SubjectToken = string;
export type AuthorizationCode = string;

export type Tokens = {
  updateToken: string;
};

export enum GRAND_TYPE {
  AUTHORIZATION_CODE = "authorization_code",
  REFRESH_TOKEN = "refresh_token",
  PASSWORD = "password",
  TOKEN_EXCHANGE = "urn:ietf:params:oauth:grant-type:token-exchange",
}

export type AuthRequest = {
  /**
   * Временный код
   *
   * Код полученный в query параметрах после авторизации пользователя.
   *
   * В параметре необходимо использовать значение code полученное приложением
   * на адрес redirect_uri.
   * Каждый code может быть использован только один раз.
   * Например, если при запросе access_token по коду авторизации
   * была допущена ошибка в каком либо из параметров и передано значение code,
   * то повторное использование данного значения code уже будет недопустимо.
   */
  code: string;
  /**
   * Идентификатор приложения, полученный при регистрации
   * */
  client_id: string;
  /**
   * URI, на который сервер передает результат авторизации,
   * если есть УЗ
   * */
  redirect_uri: string;
  grant_type: GRAND_TYPE;
};

export type AuthResponse = {
  /**
   * Токен авторизации
   */
  access_token?: AccessToken;
  /**
   * Время жизни refresh_token в секундах(30 минут).
   * Если содержит 0,  токен бессрочный.
   */
  expires_in?: number;
  /**
   * Время жизни access_token в секундах(15 минут).
   * Если содержит 0, токен бессрочный.
   */
  refresh_expires_in?: number;
  /**
   * Токен обновления
   */
  refresh_token?: UpdateToken;
  /**
   * Тип токена
   * example: bearer
   * */
  token_type?: string;
  "not-before-policy"?: number;
  /**
   * Статус сессии
   */
  session_state?: string;
  /**
   * Разделенный пробелами список областей, определяющих ресурсы,
   * к которым ваше приложение может получить доступ от имени пользователя.
   */
  scope?: string;
  /**
   * Строка кода ошибки, которую нужно использовать для
   * классификации типов возникающих ошибок и реагирования на них.
   * */
  error?: AuthResponseError;
  /**
   * Конкретное сообщение об ошибке,
   * с помощью которого разработчик может определить
   * причину возникновения ошибки проверки подлинности.
   * Никогда не используйте это поле для реагирования на ошибку в коде.
   * */
  error_description?: string;
};

export enum AuthResponseError {
  /**
   * Неправильный запрос.
   *
   * В запросе не передан обязательный параметр, либо значения переданного параметра некорректно, либо параметр присутствует в запросе несколько раз, либо весь запрос имеет неправильный формат.
   *
   * Возможные причины
   *
   * Не удалось получить идентификатор клиента из запроса.
   *
   * Отсутствие обязательных параметров в запросе.
   *
   * Переданы некорректные значения параметров.
   */
  INVALID_REQUEST = "invalid_request",
  /**
   * Неправильный клиент.
   *
   * Не удалось осуществить аутентификацию клиента.
   *
   * Возможные причины
   *
   * Не удалось получить идентификатор клиента из запроса.
   *
   * Клиент с указанным в запросе идентификатором не зарегистрирован или отключён.
   */
  INVALID_CLIENT = "invalid_client",
  /**
   *
   * Неавторизованный клиент.
   *
   * Клиент с указанным в запросе идентификатором не зарегистрирован, отключён, либо переданы неверные учётные данные клиента.
   *
   * Возможные причины
   *
   * Используется тип учётных данных клиента, отличный от разделяемого секрета.
   *
   * Переданы неверные учётные данные.
   *
   * Переданные учётные данные клиента истекли.
   */
  UNAUTHORIZED_CLIENT = "unauthorized_client",
  /**
   * В выдаче access_token отказано.
   *
   * Keycloak не выдавали временный токен,
   * токен просрочен, или по этому временному токену уже выдан access_token
   * (повторный запрос токена авторизации с тем же временным токеном).
   */
  INVALID_GRANT = "invalid_grant",
  /**
   * Неподдерживаемый тип разрешение.
   *
   * Сервер авторизации не поддерживает тип предоставления авторизации.
   *
   * Возможные причины
   *
   * Передан тип разрешения отличный от
   *
   * code,
   *
   * password,
   *
   * urn:ietf:params:oauth:grant-type:token-exchange,
   *
   * refresh_token.
   */
  UNSUPPORTED_GRANT_TYPE = "unsupported_grant_type",
}

export type LogoutResponse = {
  error?: AuthResponseError;
  error_description?: string;
};
