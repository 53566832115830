import React, { lazy, useRef } from "react";

import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { IS_DEV, IS_TESTING_STAGING, URLS } from "const";
import { useAppSelector } from "hooks/redux";
import { appSelectors } from "store/reducers/app/app-reducer";
import { useSnackbar } from "store/reducers/app/hooks";

import { AuthLayout } from "../../pages/auth-success/layout/auth-layout";
import { AppLoader } from "../common/app-loader/app-loader";
import { Snackbar } from "../common/snackbar/snackbar";
import { ProtectedRoute } from "../protected-route/protected-route";
import { SuspenseWithAppLoader } from "../suspense-with-app-loader/suspense-with-app-loader";
import { UpdatePwa } from "../update-pwa/update-pwa";

import ScrollToTop from "./scroll-to-top";
import { AppSkeleton } from "./skeleton";

const TestPage = lazy(() => import("pages/test/test-page"));
const PersonalMain = lazy(() => import("../personal-area/layout"));

const AuthSuccess = lazy(() => import("pages/auth-success"));
const AuthSales = lazy(() => import("pages/auth-sales"));

export const App = (): JSX.Element => {
  const isLoading = useAppSelector(appSelectors.selectIsLoading);
  const { actions, snackbarState } = useSnackbar();

  const snackbarRef = useRef<HTMLDivElement>(null);

  if (isLoading) {
    return <AppSkeleton />;
  }

  const isPWA = window.matchMedia("(display-mode: standalone)").matches;

  return (
    <CookiesProvider>
      <BrowserRouter>
        {isPWA && <UpdatePwa />}
        <ScrollToTop />
        <AppLoader />
        <div ref={snackbarRef}>
          <Snackbar
            title={snackbarState.title}
            type={snackbarState.type}
            text={snackbarState.text}
            isOpen={snackbarState.isOpen}
            position={snackbarState.position}
            portalContainer={snackbarRef}
            onClose={actions.clearSnackbarAlert}
            buttonText={snackbarState.buttonText}
            onButtonClick={snackbarState.onButtonClick}
          />
        </div>
        <Routes>
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <SuspenseWithAppLoader>
                  <PersonalMain />
                </SuspenseWithAppLoader>
              </ProtectedRoute>
            }
          />
          <Route path={URLS.AUTH_SUCCESS} element={<AuthLayout />}>
            <Route
              index
              element={
                <SuspenseWithAppLoader>
                  <AuthSuccess />
                </SuspenseWithAppLoader>
              }
            />
            <Route
              path=":params"
              element={
                <SuspenseWithAppLoader>
                  <AuthSuccess />
                </SuspenseWithAppLoader>
              }
            />
          </Route>
          {(IS_DEV || IS_TESTING_STAGING) && (
            <Route path={URLS.AUTH_SALES} element={<AuthLayout />}>
              <Route
                index
                element={
                  <SuspenseWithAppLoader>
                    <AuthSales />
                  </SuspenseWithAppLoader>
                }
              />
            </Route>
          )}
          {(IS_DEV || IS_TESTING_STAGING) && (
            <Route
              path={URLS.TEST}
              element={
                <SuspenseWithAppLoader>
                  <TestPage />
                </SuspenseWithAppLoader>
              }
            />
          )}
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
};
