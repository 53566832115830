import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API_URL_FOOTER } from "const";

import type {
  ISubscribeOpenInfoRequest,
  SubscribeOpenInfoResponse,
} from "./types";

/** Сервис подписки */
export const footerApi = createApi({
  reducerPath: "footerApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL_FOOTER }),

  endpoints: (build) => ({
    /** Подписка на раскрываемую информацию */
    subscribeOpenInfoRequest: build.mutation<
      SubscribeOpenInfoResponse,
      ISubscribeOpenInfoRequest
    >({
      query: (params) => ({
        url: "Subscriptions/DisclosureInfo",
        method: "POST",
        body: {
          email: params.email,
          categoryTypeSubscription: params.categoryTypeSubscription,
        },
      }),
    }),
  }),
});
