import type { FC } from "react";
import { useRef } from "react";

import { Backdrop, Loader, useClickOutside } from "ii-ui-kit";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { appActions, appSelectors } from "store/reducers/app/app-reducer";

import "./app-loader.scss";

export const AppLoader: FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(appSelectors.selectLoaderState);

  const loaderWrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(loaderWrapperRef, () => {
    dispatch(appActions.setLoader({ isLoading: false }));
  });

  return (
    <Backdrop shown={isLoading}>
      {isLoading && (
        <div className="app-loader" ref={loaderWrapperRef}>
          <Loader />
        </div>
      )}
    </Backdrop>
  );
};
