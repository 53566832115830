import { authApi } from "services/auth";
import type { ICommonResponse } from "services/common-types";
import { getUrlFromClients } from "services/utils";

import type {
  IClientProfileRequest,
  IClientProfile,
  IClientsVerifyEmailRequest,
  IClientsVerifyCodeRequest,
} from "./types";

/** Сервис клиенты */
export const clientsApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    /** Получение персональных данных клиента */
    clientProfile: builder.mutation<
      ICommonResponse<IClientProfile>,
      IClientProfileRequest
    >({
      query: (request) => ({
        url: getUrlFromClients("Clients/profile"),
        method: "POST",
        body: request,
      }),
    }),
    /** Генерация и отправка кода для подтверждения электронной почты */
    verifyEmail: builder.mutation<
      ICommonResponse<unknown>,
      IClientsVerifyEmailRequest
    >({
      query: (request) => ({
        url: getUrlFromClients("Clients/verifyEmail"),
        method: "POST",
        body: request,
      }),
    }),

    /** Подтверждение электронной почты */
    verifyEmailCode: builder.mutation<
      ICommonResponse<unknown>,
      IClientsVerifyCodeRequest
    >({
      query: (request) => ({
        url: getUrlFromClients("Clients/verifyCode"),
        method: "POST",
        body: request,
      }),
    }),
  }),
});
