import groupBy from "lodash/groupBy";

import type { Nullable } from "../entities/common";

// TODO export from uikit, reuse here

export const arrayStub = <T>(count: number, map: (key: number) => T): T[] =>
  new Array(count).fill(null).map((_, index) => map(index));

/**
 * Разделяет массив по ключу
 * Возвращает массив [ key, data ]
 * @param data
 * @param iteratee keyof data
 */
export const groupByKey = <T>(
  data: Nullable<T[]>,
  iteratee: keyof T
): [string, T[]][] => Object.entries(groupBy(data, iteratee));
