import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API_URL_STRAPI } from "const";

import type { IStrapiFetchListResponse } from "./common-types";
import type { IStaticTextModel, ISysVarsModel } from "./types";

export const strapiContentApi = createApi({
  reducerPath: "strapiContentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL_STRAPI}api/content/`,
  }),

  endpoints: (build) => ({
    /** Получить системные переменные */
    getSystemVars: build.query<
      IStrapiFetchListResponse<ISysVarsModel>,
      {
        sort?: string;
      }
    >({
      query: ({ sort = "id:ASC" }) => ({
        url: `sysvars?populate=*&sort=${sort}`,
      }),
    }),

    getStaticTexts: build.query<
      IStrapiFetchListResponse<IStaticTextModel>,
      {
        sort?: string;
      }
    >({
      query: ({ sort = "id:ASC" }) => ({
        url: `static-texts?populate=*&sort=${sort}`,
      }),
    }),
  }),
});
