import { KEYCLOAK_API, KEYCLOAK_CONFIG, URLS } from "const";

/** Генерирует ссылку для передачи url в keycloak */
export const getRedirectUri = () =>
  `${window.location.origin}${URLS.AUTH_SUCCESS}`;

/** Билдер урлов до API кейклока */
export const keycloakApiUrlBuilder = (
  withBasePath = false,
  realm: string | undefined = KEYCLOAK_CONFIG.realm
) => {
  const basePath = withBasePath ? KEYCLOAK_API : "";
  return {
    getTokensByAuthorizationCodeUrl: `${basePath}realms/${realm}/protocol/openid-connect/token`,
    updateTokenUrl: `${basePath}realms/${realm}/protocol/openid-connect/token`,
    logoutUrl: `${basePath}realms/${realm}/protocol/openid-connect/logout`,
    exchangeTokensUrl: `${basePath}realms/${realm}/protocol/openid-connect/token`,
  };
};
