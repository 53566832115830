/** Идентификаторы периодов расчета доходности */
import type { DateString } from "../../types";

/** Тип идентификатора фонда */
export type FundId = number;

/** Возможные периоды */
export enum PeriodIds {
  /** Месяц */
  month = 1,
  /** Три месяца */
  threeMonths = 2,
  /** Шесть месяцев */
  sixMonths = 3,
  /** Год */
  year = 4,
  /** Три года */
  threeYears = 5,
  /** Пять лет */
  fiveYears = 6,
}

export interface IFundItem {
  /** СЧА */
  assetsValue: number;
  /** Дата последней записи в предыдущем месяце */
  date: string;
  /** ID фонда */
  fundId: FundId;
  /** Стоимость пая */
  unitValue: number;
}

/** Модель фонда из запроса топ фондов по прибыли */
export interface ITopFundModel {
  /** ID фонда */
  fundId: FundId;
  /** Доходность */
  profitability: number;
}

export interface IFundPeriod {
  /** Конечная дата */
  endDate: string;
  /** ИД Продукта */
  fundId: FundId;
  /** Номер периода */
  periodId: number;
  /** Доходность в процентах */
  profitability: number;
  /** Дата начала периода */
  startDate: string;
}

export interface IFundDynamicWithPeriods extends IFundItem {
  /** Месяц */
  month: number;
  /** Периоды */
  periods: IFundPeriod[];
}

/** Ответ на запрос топ фондов по прибыли */
export interface ITopFundsByProfitabilityResponse {
  /** Идентификатор периода */
  periodId: PeriodIds;
  /** Начальная дата */
  startDate: string;
  /** Конечная дата */
  endDate: string;
  /** Фонды */
  funds: ITopFundModel[];
}

/** Идентификаторы фондов */
export type LegalInfoKeys =
  | 639383
  | 17586
  | 17587
  | 17590
  | 541872
  | 7596108
  | 36081295
  | 39270561
  | 48141850;

/** Ответ на запрос получения доходности ПИФ регуляторные периоды */
export interface IFundProfitabilityRegular {
  /** Номер периода */
  periodId: number;
  /** Доходность в процентах */
  profitability: number | null;
  /** Дата окончания периода */
  endDate: string;
  /** Дата начала периода */
  startDate: string;
}

/** Ответ на запрос последних дат записи текущего месяца */
export interface IFundFirstLastDateQuotesResponse {
  /** ID фонда (Int) */
  id: FundId;
  /** Дата первой записи по этому фонду  */
  firstQuotesDate: DateString;
  /** Дата последней записи по этому фонду на текущий день */
  lastQuotesDate: DateString;
}

/** Ответ на запрос информацию по доходности от даты из запроса - заданный период */
export type IFundProfitabilityPeriod = Record<
  string,
  {
    /** Доходность в процентах (Float Number) */
    profitability: number;
    /** Дата начала периода */
    startDate: DateString;
    /** Дата окончания периода */
    endDate: DateString;
  }
>;

/** Запрос последних дат записи текущего месяца */
export interface IFundFirstLastDateQuotesRequest {
  /** Массив фондов по которым необходимо вернуть данные */
  ids: FundId[];
  /**
   * Период в месяцах
   *
   * (Например, если startDate = 24.08.2022 и period = 60, то необходимо отдавать данные за период от 24.08.2017 до 24.08.2022 включительно)
   * */
  period?: number;
  /** Дата от которой необходимо отсчитывать период */
  lastQuotesDate: DateString;
}

export type IGetFundProfitabilityPeriodWithLastQuotesDateRequest = Omit<
  IFundFirstLastDateQuotesRequest,
  "lastQuotesDate"
>;
