import type { FC } from "react";
import React, { Suspense } from "react";

import { AppSkeleton } from "../app/skeleton";

import "./suspense-with-app-loader.scss";

export const SuspenseWithAppLoader: FC = ({ children }) => (
  <div className="suspense-loader">
    <Suspense fallback={<AppSkeleton />}>{children}</Suspense>
  </div>
);
