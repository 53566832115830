import trim from "lodash/trim";

import { summaryApi } from "services/summary/summary-service";
import type { ISummaryApi } from "services/summary/types";
import { SummaryTypeEnum } from "services/summary/types";
import { todayLocaleRu } from "utils/date";

import type { AppThunk } from "../../store";

import { DOCS_TABS, IIA_TABS, MAIN_TABS, PIF_TABS, TM_TABS } from "./consts";
import { tabsUtils } from "./utils";

import {
  setActiveDocsTab,
  setActiveIIATab,
  setActiveMainTab,
  setActivePifTab,
  setActiveTMTab,
  setContractId,
} from "./";

/**
 * Парсинг урла ИИС на витрине
 */
const parseIIAShowcaseUrl =
  (pathParts: string[]): AppThunk<void> =>
  (dispatch) => {
    if (
      pathParts[2] &&
      Object.values(IIA_TABS).includes(pathParts[2] as IIA_TABS)
    ) {
      dispatch(setActiveIIATab(pathParts[2] as IIA_TABS));
    }
  };

/**
 * Парсинг урла ДУ на витрине
 */
const parseTMShowcaseUrl =
  (trimmedPathname: string, contractDUList: ISummaryApi[]): AppThunk<void> =>
  (dispatch) => {
    const match = tabsUtils.parseTrustManagementUrl(trimmedPathname);

    if (match) {
      const tmTab = match.tmTab;
      let contractId = match.contractId;

      // Если contractId не передан в урл и мы имеем только один контракт ду, то
      // устанавливаем активный contractId равным этому единственному контракту
      if (!contractId && contractDUList.length === 1) {
        contractId = String(contractDUList[0].Id);
      }

      if (contractId) {
        if (contractDUList.find((item) => item.Id === Number(contractId))) {
          dispatch(setContractId(Number(contractId)));
          dispatch(setActiveTMTab(tmTab as TM_TABS));
        }
      } else {
        dispatch(setContractId(null));
        dispatch(setActiveTMTab(TM_TABS.TRUST_MANAGEMENT));
      }
    }
  };

/**
 * Парсинг урла ПИФ на витрине
 */
const parseMFShowcaseUrl =
  (pathParts: string[]): AppThunk<void> =>
  (dispatch) => {
    if (
      pathParts[2] &&
      Object.values(PIF_TABS).includes(pathParts[2] as PIF_TABS)
    ) {
      dispatch(setActivePifTab(pathParts[2] as PIF_TABS));
    }
  };

/**
 * Парсинг урла и заполнение стора
 */
export const parseUrl = (): AppThunk<Promise<unknown>> => async (dispatch) => {
  const res = await dispatch(
    summaryApi.endpoints.getSummaryStructure.initiate({
      onDate: todayLocaleRu,
    })
  );

  const contractDUList =
    res.data?.filter((item) => item.Type === SummaryTypeEnum.DU) ?? [];

  const trimmedPathname = trim(location.pathname, "/");
  const pathParts = trimmedPathname.split("/");

  if (
    pathParts[0] &&
    Object.values(MAIN_TABS).includes(pathParts[0] as MAIN_TABS)
  ) {
    const activeMainTab = pathParts[0];
    dispatch(setActiveMainTab(activeMainTab as MAIN_TABS));

    switch (activeMainTab) {
      case MAIN_TABS.DOCUMENTS:
        if (
          pathParts[1] &&
          Object.values(DOCS_TABS).includes(pathParts[1] as DOCS_TABS)
        ) {
          dispatch(setActiveDocsTab(pathParts[1] as DOCS_TABS));
        } else {
          dispatch(setActiveDocsTab(DOCS_TABS.MAIN));
        }
        break;
      case MAIN_TABS.SHOWCASE:
        switch (pathParts[1]) {
          case "iia":
            dispatch(parseIIAShowcaseUrl(pathParts));
            break;
          case "trust-management":
            dispatch(parseTMShowcaseUrl(trimmedPathname, contractDUList));
            break;

          case "pif":
            dispatch(parseMFShowcaseUrl(pathParts));
            break;
        }

        break;
    }
  }
};
