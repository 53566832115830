import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch } from "hooks/redux";

import { parseUrl } from "./thunks";

export const useTabsRouting = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(parseUrl());
  }, [dispatch, location]);
};
