/**
 * Дата в строковом формате дд-мм-гггг
 */
export type DateString = string;

/** Тип записей для таблиц с раскрыванием строки */
export type ExpandedRecordType<T> = T & { key: number };

/**
 * Доступные фонды
 */
export enum Funds {
  /**
   * Моё будущее
   */
  "MyFuture" = "My future",
  /**
   * Мои облигации
   */
  "MyBonds" = "My bonds",
  /**
   * Мой сейф
   */
  "MySafe" = "My safe",
  /**
   * Мои акции
   */
  "MyShares" = "My shares",
  /**
   * Моя пенсия
   */
  "MyPension" = "My pension",
  /**
   * Ингосстрах – Международные рынки
   */
  "IngosstrakhInternationalMarkets" = "Ingosstrakh - International Markets",
  /**
   * Ингосстрах – передовые технологии
   */
  "IngosstrakhAdvancedTechnologies" = "Ingosstrakh - advanced technologies",
  /**
   * Ингосстрах – драгоценные металлы
   */
  "IngosstrakhPreciousMetals" = "Ingosstrakh - precious metals",
  /**
   * Ингосстрах – валютные облигации
   */
  "IngosstrakhForeignCurrencyBonds" = "Ingosstrakh - foreign currency bonds",
}
