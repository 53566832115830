import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { todayLocaleRu } from "utils/date";

type BriefcaseSummaryState = {
  onDate: string;

  minDate: string;

  maxDate: string;
};

const initialState: BriefcaseSummaryState = {
  onDate: todayLocaleRu,
  minDate: todayLocaleRu,
  maxDate: todayLocaleRu,
};

export const briefcaseSummarySlice = createSlice({
  name: "briefcaseSummary",
  initialState,
  reducers: {
    setOnDate: (state, { payload }: PayloadAction<string>) => {
      state.onDate = payload;
    },

    setDates: (state, { payload }: PayloadAction<BriefcaseSummaryState>) =>
      payload,

    clearStore: () => initialState,
  },
});

export const briefcaseSummarySliceActions = briefcaseSummarySlice.actions;
