import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API_URL_INFO_API } from "const";

import type {
  FundId,
  IFundFirstLastDateQuotesRequest,
  IFundFirstLastDateQuotesResponse,
  IFundItem,
  IFundPeriod,
  IFundProfitabilityPeriod,
  ITopFundsByProfitabilityResponse,
  IFundProfitabilityRegular,
  LegalInfoKeys,
} from "./types";

export const infoAPI = createApi({
  reducerPath: "infoAPI",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL_INFO_API }),

  endpoints: (build) => ({
    /** Получение информации  о стоимости пая и СЧА на указанный период по указанному фонду */
    getFundQuotesDynamic: build.mutation<
      IFundItem[],
      {
        id: number;
        startDate: string;
        endDate: string;
      }
    >({
      query: (data: { id: number; startDate: string; endDate: string }) => ({
        url: `/api/Info/FundQuotesDynamic/${data.id}`,
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
    /** Метод возвращает доходность ПИФ регуляторные периоды */
    getFundProfitabilityRegular: build.mutation<
      Record<LegalInfoKeys, IFundProfitabilityRegular[]>,
      number[]
    >({
      query: (ids) => ({
        url: "/api/Info/FundProfitabilityRegular",
        method: "POST",
        body: ids,
      }),
    }),
    /** Получение информации о доходности ПИФ за указанный период или интервалы из справочника periodId */
    getFund: build.mutation<
      IFundPeriod[],
      {
        id: number;
        startDate: string;
        endDate: string;
      }
    >({
      query: (data: { id: number; startDate: string; endDate: string }) => ({
        url: `/api/Info/FundProfitability/${data.id}`,
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
    /** Получить топ фондов по прибыли */
    topFundsByProfitability: build.mutation<
      ITopFundsByProfitabilityResponse,
      number[]
    >({
      query: (request) => ({
        method: "POST",
        url: "/api/Info/TopFundsByProfitability",
        body: request,
      }),
    }),
    /** Получить последнее даты записи текущего месяца для переданных фондов */
    fundFirstLastDateQuotes: build.mutation<
      IFundFirstLastDateQuotesResponse[],
      FundId[]
    >({
      query: (request) => ({
        method: "POST",
        url: "/api/Info/FundFirstLastDateQuotes",
        body: request,
      }),
    }),
    /** Получить информацию по доходности от даты из запроса - заданный период */
    fundProfitabilityPeriod: build.mutation<
      IFundProfitabilityPeriod,
      IFundFirstLastDateQuotesRequest
    >({
      query: ({ lastQuotesDate, ids, period = 60 }) => ({
        method: "POST",
        url: `/api/Info/FundProfitabilityPeriod?date=${lastQuotesDate}&period=${period}`,
        body: ids,
      }),
    }),
  }),
});
