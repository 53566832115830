import type { RootState } from "../../store";

const selectTmTabs = (state: RootState) => state.tabs.tmTabs;

const selectMainTabs = (state: RootState) => state.tabs.mainTabs;

const selectPifTabs = (state: RootState) => state.tabs.pifTabs;

const selectIIATabs = (state: RootState) => state.tabs.iiaTabs;

const selectDocsTabs = (state: RootState) => state.tabs.docsTabs;

export const tabsSelectors = {
  selectDocsTabs,
  selectTmTabs,
  selectMainTabs,
  selectPifTabs,
  selectIIATabs,
};
