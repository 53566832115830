import { RiskProfile } from "services/products/types";

/** Маппинг уровня риска, который приходит от продуктового каталога в формат для карточки фонда */
export const mapRiskProfileForCards = (riskProfile: RiskProfile) => {
  switch (riskProfile) {
    case RiskProfile.low:
      return "low";
    case RiskProfile.medium:
      return "medium";
    case RiskProfile.high:
      return "high";
    default:
      return "low";
  }
};
