import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { DOCS_TABS, IIA_TABS, MAIN_TABS, PIF_TABS, TM_TABS } from "./consts";
import type { TabsState } from "./types";

const initialState: TabsState = {
  mainTabs: {
    activeTab: MAIN_TABS.BRIEFCASE,
  },
  pifTabs: {
    activeTab: PIF_TABS.BRIEFCASE,
  },
  tmTabs: {
    activeTab: TM_TABS.TRUST_MANAGEMENT,
    contractId: null,
  },
  iiaTabs: {
    activeTab: IIA_TABS.IIA_BRIEFCASE,
  },
  docsTabs: {
    activeTab: DOCS_TABS.MAIN,
  },
};

export const tabsSlice = createSlice({
  name: "custom-tabs",
  initialState,
  reducers: {
    setActiveMainTab: (state, { payload }: PayloadAction<MAIN_TABS>) => {
      state.mainTabs.activeTab = payload;
    },

    setActivePifTab: (state, { payload }: PayloadAction<PIF_TABS>) => {
      state.pifTabs.activeTab = payload;
    },

    setActiveTMTab: (state, { payload }: PayloadAction<TM_TABS>) => {
      state.tmTabs.activeTab = payload;
    },

    setContractId: (state, { payload }: PayloadAction<number | null>) => {
      state.tmTabs.contractId = payload;
    },

    setActiveIIATab: (state, { payload }: PayloadAction<IIA_TABS>) => {
      state.iiaTabs.activeTab = payload;
    },

    setActiveDocsTab: (state, { payload }: PayloadAction<DOCS_TABS>) => {
      state.docsTabs.activeTab = payload;
    },

    clearStore: () => initialState,
  },
});

export const {
  setActiveMainTab,
  setActivePifTab,
  setActiveTMTab,
  setContractId,
  setActiveDocsTab,
  setActiveIIATab,
  clearStore: clearTabsStore,
} = tabsSlice.actions;
