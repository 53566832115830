import { isWithinInterval, max, min } from "date-fns";
import orderBy from "lodash/orderBy";

import type { PieData } from "components/common/charts/pie/type";
import {
  IIACommon,
  MutualFundsCommon,
  TMCommon,
} from "components/personal-area/briefcase/briefcase";
import { funds } from "components/personal-area/popular-funds/assets/funds";
import type { IPopularFund } from "components/personal-area/popular-funds/types";
import { INGOS_SITE, INGOS_SITE_ROUTES } from "const";
import type { IBriefcaseGroup } from "entities/briefcase";
import type { ICommonResponse } from "services/common-types";
import type { IProductInfoById } from "services/products/types";
import type { ISummaryApi } from "services/summary/types";
import { SummaryTypeEnum } from "services/summary/types";
import { checkUnblockedMutualFundById } from "utils/check-available-mutual-funds";
import { isAfterBlockDate, isBetweenBlockedRange } from "utils/date";
import { numberFormat } from "utils/formatters";
import { mapRiskProfileForCards } from "utils/map-risk-profile";

import type { IMatchedProductsAndContracts } from "../../services/briefcase/types";
import { MatchedProductsAndContractsEnum } from "../../services/briefcase/types";
import type { IFundProfitabilityPeriod } from "../../services/info";
import type {
  IGetContactsResponse,
  IGetMainDataResponse,
  IGetMultipleMainDataResponse,
  IMFsTable,
  IProductsAnalytic,
} from "../../types/interface";
import { sortPieChartData } from "../../utils/utils";
import type { ProductsPieValue } from "../client-reporting/const";
import { getMaxDate } from "../client-reporting/main-container/utils";

/**
 * Формирует информацию по ПИФ
 */
export const getMutualFundsInfo = (
  matchedProductsAndContracts: IMatchedProductsAndContracts[],
  multipleMainData: IGetMultipleMainDataResponse,
  mfTable: IMFsTable,
  durationDateTo: Date
): IBriefcaseGroup => ({
  ...MutualFundsCommon,
  items: matchedProductsAndContracts.map((item) => {
    const currentProduct = multipleMainData.find(
      ({ productId }) => productId === Number(item.product.Id)
    );

    const isHold = item.contract?.isBlocked && item.contract.isOpen;

    const currentMfTableElement = mfTable.data.find(
      ({ tool: { instrumentName } }) => item.product.Name === instrumentName
    );

    return {
      title: item.product.Name,
      id: item.product.Id,
      status: item.product.Status,
      pif: {
        date: durationDateTo,
        quantity: currentMfTableElement?.unitNumber ?? 0,
        cost: currentMfTableElement?.positionPrice.amount ?? 0,
        profit: currentProduct?.data.mainInfo.income.amount ?? 0,
        yield: currentProduct?.data.mainInfo.profitability ?? 0,
      },
      totalCost: currentProduct?.data.mainInfo.assetValue.amount ?? 0,
      totalYield: currentProduct?.data.mainInfo.profitability ?? 0,
      totalYieldMoney: currentProduct?.data.mainInfo.income.amount ?? 0,
      comment: item.product.Comment,
      type: SummaryTypeEnum.PIF,
      isHold,
    };
  }),
});

/**
 * Формирует информацию по ИИС
 */
export const getIIAInfo = (
  iiaContracts: IMatchedProductsAndContracts[],
  multipleMainData: IGetMultipleMainDataResponse
): IBriefcaseGroup => {
  const iiaContract = iiaContracts[0]?.product;

  if (!iiaContract) {
    return {
      ...IIACommon,
      items: [],
    };
  }

  const currentProduct = multipleMainData.find(
    ({ productId }) => productId === iiaContract.Id
  );
  return {
    ...IIACommon,
    items: [
      {
        title: iiaContract.Name,
        id: iiaContract.Id,
        totalCost: currentProduct?.data.mainInfo.assetValue.amount ?? 0,
        totalYield: currentProduct?.data.mainInfo.profitability ?? 0,
        totalYieldMoney: currentProduct?.data.mainInfo.income.amount ?? 0,
        type: iiaContract.Type,
        comment: iiaContract.Comment,
      },
    ],
  };
};

/**
 * Формирует информацию по ДУ
 */
export const getTMInfo = (
  tmContracts: IMatchedProductsAndContracts[],
  multipleMainData: IGetMultipleMainDataResponse
): IBriefcaseGroup => ({
  ...TMCommon,
  items: tmContracts.map(({ product }) => {
    const currentProduct = multipleMainData.find(
      ({ productId }) => productId === product.Id
    );
    return {
      title: product.Name,
      id: product.Id,
      totalCost: currentProduct?.data.mainInfo.assetValue.amount ?? 0,
      totalYield: currentProduct?.data.mainInfo.profitability ?? 0,
      totalYieldMoney: currentProduct?.data.mainInfo.income.amount ?? 0,
      type: product.Type,
      comment: product.Comment,
    };
  }),
});

/**
 * Формирует данные для отображения в портфеле
 */
export const getBriefcaseInfo = (
  mfContracts: IMatchedProductsAndContracts[],
  tmContracts: IMatchedProductsAndContracts[],
  iiaContract: IMatchedProductsAndContracts[],
  multipleMainData: IGetMultipleMainDataResponse,
  mfTable: IMFsTable,
  durationDateTo: Date
): IBriefcaseGroup[] => [
  getMutualFundsInfo(mfContracts, multipleMainData, mfTable, durationDateTo),
  // TODO: Change filterMatchedProductsAndContractsForShow2(iiaContract) to iiaContract after fix Summary. Task DGTST-5322
  getIIAInfo(
    filterMatchedProductsAndContractsForShow2(iiaContract),
    multipleMainData
  ),
  getTMInfo(tmContracts, multipleMainData),
];

/**
 * Возвращает сводную информацию по портфелю
 * (стоимость активов, доход и доходность за период)
 */
export const getBriefcaseTotal = (mainData: IGetMainDataResponse) =>
  mainData.mainInfo;

/**
 * Возвращает сводную информацию по портфелю для продуктов
 * (стоимость активов, доход и доходность за период)
 */
export const getBriefcaseTotalByIds = (
  multipleMainData: IGetMultipleMainDataResponse,
  ids: number[]
) => {
  const currentMainDataProducts = multipleMainData.filter(({ productId }) =>
    ids.includes(productId)
  );

  return currentMainDataProducts.reduce(
    (acc, el) => {
      acc.profit = acc.profit + el.data.mainInfo.income.amount;
      acc.total = acc.total + el.data.mainInfo.assetValue.amount;
      acc.profitability = acc.profitability + el.data.mainInfo.profitability;

      return acc;
    },
    {
      profit: 0,
      total: 0,
      profitability: 0,
    }
  );
};

/** Проверить заблокирован ли контракт для пай чарта */
export const checkIsBlockedForPie = (
  product: IProductsAnalytic,
  contracts: IMatchedProductsAndContracts[],
  /** Дата на которую отображается pie chart */
  onDate: Date
) => {
  const isBlocked =
    (isBetweenBlockedRange(onDate) &&
      contracts.some(
        (contract) =>
          checkUnblockedMutualFundById(contract.contract?.contractID || 0) &&
          contract.contract?.isOpen &&
          contract.contract.contractID === Number(product.name)
      )) ||
    (isAfterBlockDate(onDate) &&
      contracts.some(
        (contract) =>
          contract.contract?.isBlocked &&
          contract.contract?.isOpen &&
          contract.contract.contractID === Number(product.name)
      ));

  return isBlocked;
};

/**
 * Формирует данные для отображения в пай чарте
 */
export const getBriefcasePieDataFromAnalytics = (
  productsAnalytics: IProductsAnalytic[],
  /** Контракты */
  contracts: IMatchedProductsAndContracts[],
  /** Тип контракта. Если не передано - Все */
  /** Дата на которую отображается pie chart */
  onDate: Date,
  contractTypes?: ProductsPieValue[]
): PieData[] =>
  sortPieChartData(
    productsAnalytics
      .filter(({ contractType }) =>
        contractTypes ? contractTypes.includes(contractType) : true
      )
      .map((s) => {
        const isBlocked = checkIsBlockedForPie(s, contracts, onDate);

        const percentValue = numberFormat({
          value: s.percent || 0,
          postfix: "%",
        });

        return {
          name: s.displayName,
          value: s.percent,
          legendValue: `${percentValue}`,
          tooltipValue: `Доля портфеля ${percentValue}`,
          isBlocked,
        };
      })
  );

/**
 * Формирует данные для отображения в пай чарте
 */
export const getBriefcasePieDataFromAnalyticsWithCalculate = (
  productsAnalytics: IProductsAnalytic[],
  /** Тип контракта. Если не передано - Все */
  contractTypes: ProductsPieValue[],
  /** Контракты */
  contracts: IMatchedProductsAndContracts[],
  /** Дата на которую отображается pie chart */
  onDate: Date
): PieData[] =>
  sortPieChartData(
    getPieData(
      productsAnalytics
        .filter(({ contractType }) =>
          contractTypes ? contractTypes.includes(contractType) : true
        )
        .map((s) => {
          const isBlocked = checkIsBlockedForPie(s, contracts, onDate);

          return {
            value: s.value,
            name: s.displayName,
            isBlocked,
          };
        })
    )
  );

/**
 * Формирует данные для в нужном формате для пай чарта
 */
export const getPieData = (
  items: Pick<PieData, "name" | "value" | "isBlocked">[]
): PieData[] => {
  const totalSum = items.reduce((acc, { value }) => acc + value, 0);
  return items.map(({ value, name, isBlocked }) => {
    const percentValue = numberFormat({
      value: (value / totalSum) * 100 || 0,
      postfix: "%",
    });
    return {
      value: value || 1,
      name,
      legendValue: `${percentValue}`,
      tooltipValue: `Доля портфеля ${percentValue}`,
      isBlocked,
    };
  });
};

export const emptyLinkProduct = (type: SummaryTypeEnum) => {
  switch (type) {
    case SummaryTypeEnum.PIF:
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.PIF}`;
    case SummaryTypeEnum.DU:
      return `${INGOS_SITE}/${INGOS_SITE_ROUTES.TM}`;
    default:
      return;
  }
};

/** Получение списка продуктов по типу */
export const getProductsByTypes = (
  summary: ISummaryApi[],
  types: SummaryTypeEnum[]
): ISummaryApi[] => summary.filter((el) => types.includes(el.Type));

/** Получение списка продуктов по типу */
export const getMatchedProductsAndContractsByTypes = (
  productsAndContracts: IMatchedProductsAndContracts[],
  types: SummaryTypeEnum[]
): IMatchedProductsAndContracts[] =>
  productsAndContracts.filter((el) => types.includes(el.product.Type));

/**
 * Формирует данные для отображения в пай чарте
 */
export const getFundCardData = (
  products: ICommonResponse<IProductInfoById>[],
  fundProfitabilityInfo: IFundProfitabilityPeriod
): IPopularFund[] => {
  const groupedProducts = products
    .filter((product) => product.body)
    .reduce((acc, el) => {
      acc[el.body.fansyId] = el.body;
      return acc;
    }, {} as { [p: string]: IProductInfoById });

  return funds.map((item) => {
    const currentProduct = groupedProducts[item.id];
    return {
      ...item,
      fund: {
        ...item.fund,
        title: currentProduct ? currentProduct.siteName : item.fund.title,
        profit: numberFormat({
          value: fundProfitabilityInfo[item.id].profitability,
          digits: 2,
          postfix: "%",
        }),
        riskLevel: currentProduct
          ? mapRiskProfileForCards(currentProduct.riskProfile)
          : item.fund.riskLevel,
      },
    };
  });
};

/**
 * Фильтрует контракты, которые удалось найти в продуктах c Rate!==0
 * Пример: allContract filtered by pifSummary[]
 *         return matchedContractsByPifSummaryId[]
 * @param contracts
 * @param summaryProducts
 */
export const getMatchedContractBySummaryProductsIdsWithoutRateIsZero = (
  contracts: IGetContactsResponse[] | undefined,
  summaryProducts: ISummaryApi[] | undefined
) =>
  contracts?.filter(
    (contract) =>
      summaryProducts?.some(
        (product) => product.Id === contract.contractID && product.Rate
      ) ?? []
  );

/**
 * Фильтрует контракты, которые удалось найти в продуктах c Rate!==0
 * Пример: allContract filtered by pifSummary[]
 *         return matchedContractsByPifSummaryId[]
 * @param contracts
 * @param summaryProducts
 */
export const getMatchedContractBySummaryProductsIds = (
  contracts: IGetContactsResponse[] | undefined,
  summaryProducts: ISummaryApi[] | undefined
) =>
  contracts?.filter(
    (contract) =>
      summaryProducts?.some((product) => product.Id === contract.contractID) ??
      []
  );

/**
 * Проверяет находится ли дата в пределах интервала durationDateFrom-durationDateTo
 * @param contract - контракт
 * @param date - дата
 *
 * Важно!!! durationDateFrom может быть больше durationDateTo, когда договор
 * только что открыли и по нему еще нет операций. Поэтому для определения начальной и
 * конечной дат интервала используются функции min/max
 */
export const isWithinContractDuration = (
  contract: IGetContactsResponse,
  date: Date
) =>
  isWithinInterval(date, {
    start: min([
      new Date(contract.durationDateFrom),
      new Date(contract.durationDateTo),
    ]),
    end: max([
      new Date(contract.durationDateFrom),
      new Date(contract.durationDateTo),
    ]),
  });

/**
 * Фильтрует продукты, которые удалось найти в контрактах.
 * @param contracts - список всех контрактов инвестора
 * @param summaryProducts - список summary
 * @param onDate - выбранная дата
 * @param selectedContractId - ид выбранного контракта
 */
export const getMatchedProductsAndContracts = (
  contracts: IGetContactsResponse[] | undefined,
  summaryProducts: ISummaryApi[] | undefined,
  onDate: Date,
  selectedContractId: number | null
) => {
  const filteredProducts = selectedContractId
    ? summaryProducts?.filter((item) => item.Id === selectedContractId)
    : summaryProducts;

  return filteredProducts?.reduce((acc, product) => {
    const currentContract = contracts?.find(
      (contract) => product.Id === contract.contractID
    );

    if (!product.Rate && currentContract) {
      const condition = isWithinContractDuration(currentContract, onDate);
      return [
        ...acc,
        <IMatchedProductsAndContracts>{
          type: condition
            ? MatchedProductsAndContractsEnum.isWithinInterval
            : MatchedProductsAndContractsEnum.isHasContractAndRateZero,
          contract: currentContract,
          product,
        },
      ];
    }

    if (!product.Rate) {
      return [
        ...acc,
        <IMatchedProductsAndContracts>{
          type: MatchedProductsAndContractsEnum.isNotHasContractAndRateZero,
          contract: null,
          product,
        },
      ];
    }

    if (!currentContract) {
      return [
        ...acc,
        <IMatchedProductsAndContracts>{
          type: MatchedProductsAndContractsEnum.isNotHasContract,
          contract: null,
          product,
        },
      ];
    }

    if (isWithinContractDuration(currentContract, onDate)) {
      return [
        ...acc,
        <IMatchedProductsAndContracts>{
          type: MatchedProductsAndContractsEnum.isWithinInterval,
          contract: currentContract,
          product,
        },
      ];
    }

    return [
      ...acc,
      <IMatchedProductsAndContracts>{
        contract: null,
        product,
      },
    ];
  }, [] as IMatchedProductsAndContracts[]);
};

export const filterMatchedProductsAndContractsForShow = (
  productsAndContracts: IMatchedProductsAndContracts[]
) =>
  productsAndContracts.filter(
    (item) =>
      item.type ===
        MatchedProductsAndContractsEnum.isNotHasContractAndRateZero ||
      item.type === MatchedProductsAndContractsEnum.isWithinInterval
  );

// TODO: Remove after fix Summary. Task DGTST-5322

export const filterMatchedProductsAndContractsForShow2 = (
  productsAndContracts: IMatchedProductsAndContracts[]
) =>
  productsAndContracts.filter(
    (item) =>
      item.type ===
        MatchedProductsAndContractsEnum.isNotHasContractAndRateZero ||
      item.type === MatchedProductsAndContractsEnum.isWithinInterval ||
      item.type === MatchedProductsAndContractsEnum.isHasContractAndRateZero
  );

// TODO: Remove after fix Summary. Task DGTST-5322

export const sortMatchedProductsAndContractsForShow = (
  productsAndContracts: IMatchedProductsAndContracts[]
) => {
  const isIIS = productsAndContracts.some((p) =>
    [
      SummaryTypeEnum.IIS,
      SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION,
      SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED,
    ].includes(p.product.Type)
  );
  return isIIS
    ? orderBy(
        productsAndContracts,
        [
          (p) => {
            if (p.type === MatchedProductsAndContractsEnum.isWithinInterval) {
              return 100;
            }

            if (
              p.type ===
              MatchedProductsAndContractsEnum.isHasContractAndRateZero
            ) {
              return 50;
            }

            if (
              p.type ===
              MatchedProductsAndContractsEnum.isNotHasContractAndRateZero
            ) {
              return 20;
            }

            return;
          },
          (p) =>
            p.contract ? new Date(p.contract.durationDateTo) : new Date(),
        ],
        ["desc", "asc"]
      )
    : productsAndContracts;
};

/**
 * Возвращает минимальную дату из переданной даты или максимальной даты из контрактов
 * @param dateFromPicker
 * @param contracts
 */
export const getMinDateOnDatePickerOrContracts = (
  dateFromPicker: Date,
  contracts: IGetContactsResponse[]
): Date => min([dateFromPicker, getMaxDate(contracts)]);

export const getCalculatedProfitabilityOnDate = (
  fundProfitabilityInfo: IFundProfitabilityPeriod
) => fundProfitabilityInfo[funds[0].id].endDate;
