import { LogoIcon } from "assets";

import "./index.scss";

export const AuthHeader = () => (
  <div className="auth-header">
    <div className="auth-header__logo">
      <LogoIcon />
    </div>
  </div>
);
