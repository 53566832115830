import React from "react";

import { SkeletonBase } from "ii-ui-kit";

import "./index.scss";

export const AppSkeleton = () => (
  <div className="app-skeleton">
    <SkeletonBase
      width="100%"
      height="100%"
      className="app-skeleton__main"
      color="light"
    >
      <SkeletonBase
        className="app-skeleton__header"
        width="100%"
        height="50px"
        animated
      />
      <div className="app-skeleton__tabs">
        <SkeletonBase width="10%" height="20px" animated />
        <SkeletonBase width="10%" height="20px" animated />
        <SkeletonBase width="10%" height="20px" animated />
        <SkeletonBase width="10%" height="20px" animated />
        <SkeletonBase width="10%" height="20px" animated />
      </div>
      <SkeletonBase className="app-skeleton__content" width="100%" animated />
    </SkeletonBase>
  </div>
);
