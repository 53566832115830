import type { FC } from "react";
import React, { useCallback, useState } from "react";

import classNames from "classnames";
import { useToggle } from "ii-ui-kit";

import { Paper } from "components/common/paper";
import { Currency, URLS } from "const";
import type { IBriefcaseGroup, IBriefcaseGroupItem } from "entities/briefcase";
import FreeCash from "pages/client-reporting/main-container/main-info-container/main-info/free-cash/free-cash";
import { SummaryTypeEnum } from "services/summary/types";
import { numberFormat } from "utils/formatters";

import { BriefcaseContainer } from "./briefcase-container/briefcase-container";
import { BriefcaseModal } from "./briefcase-modal/briefcase-modal";
import { BriefcaseSingle } from "./briefcase-single";
import { getTotalProfit } from "./utils";

import "./briefcase.scss";

export const getIsSingleBriefcaseItem = (items: IBriefcaseGroup[]) =>
  Boolean(items.length === 1 && items[0].items && items[0].items.length === 1);

export const Briefcase: FC<{
  items: IBriefcaseGroup[];

  total?: number;

  profit?: number;

  profitability?: number;
}> = ({ items, total, profit, profitability }) => {
  const [isOpenModal, toggleModal, setToggleModal] = useToggle(false);
  const [modalOpenedByFundId, setModalOpenedByFyndId] = useState(0);
  const isSingle = getIsSingleBriefcaseItem(items);

  const totalProfit = getTotalProfit(profit ?? 0, profitability);

  const handleOpenModal = useCallback(
    (id: number) => {
      setModalOpenedByFyndId(id);
      setToggleModal(true);
    },
    [setToggleModal]
  );

  const profitClasses = classNames("briefcase-block__total-profit", {
    "briefcase-block__total-profit--green": profit && profit > 0,
    "briefcase-block__total-profit--red": profit && profit < 0,
    "briefcase-block__total-profit--secondary": profit === 0,
  });

  return (
    <>
      {isSingle ? (
        <BriefcaseSingle
          item={items[0]?.items?.[0] as IBriefcaseGroupItem}
          onOpenModal={handleOpenModal}
        />
      ) : (
        <Paper className="briefcase-wrapper">
          <div className="briefcase-block">
            <div className="briefcase-block__savings">
              <div className="briefcase-block__savings--title">В портфеле</div>
              <div className="briefcase-block__savings--hoarding">
                {numberFormat({ value: total ?? 0, postfix: Currency.Ruble })}
              </div>
            </div>
            {Boolean(total) && (profit || profit === 0) && (
              <div className="briefcase-block__total">
                <div className="briefcase-block__total--title">
                  За всё время
                </div>
                <div className={profitClasses}>
                  {`${totalProfit.total} (${totalProfit.profit})`}
                </div>
              </div>
            )}
          </div>
          <FreeCash isMain />
          {items.map(
            ({ type, title, slogan, link, emptyLink, items }, index) => (
              <BriefcaseContainer
                type={type}
                key={`${title} ${index}`}
                title={title}
                slogan={slogan}
                link={link}
                emptyLink={emptyLink}
                items={items}
                onOpenModal={handleOpenModal}
              />
            )
          )}
        </Paper>
      )}
      <BriefcaseModal
        fundId={modalOpenedByFundId}
        isDisplay={isOpenModal}
        onClose={toggleModal}
      />
    </>
  );
};

export const MutualFundsCommon: Pick<
  IBriefcaseGroup,
  "title" | "link" | "emptyLink" | "slogan" | "type"
> = {
  type: SummaryTypeEnum.PIF,
  title: "Паевые фонды",
  link: URLS.SHOWCASE.PIF.MAIN,
  slogan: "Легкий старт в инвестициях",
};

export const IIACommon: Pick<
  IBriefcaseGroup,
  "title" | "link" | "emptyLink" | "slogan" | "type"
> = {
  type: SummaryTypeEnum.IIS,
  title: "Стратегии ИИС",
  link: URLS.SHOWCASE.IIS.MAIN,
  slogan: "Стратегии с налоговыми привилегиями",
};

export const TMCommon: Pick<
  IBriefcaseGroup,
  "title" | "link" | "emptyLink" | "slogan" | "type"
> = {
  type: SummaryTypeEnum.DU,
  title: "Доверительное управление",
  link: URLS.SHOWCASE.TRUST_MANAGEMENT.MAIN,
  slogan: "Профессиональное управление активами",
};

export { BriefcaseSkeleton } from "./skeleton";
